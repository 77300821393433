import { isEmpty } from "lodash";
import { useQuery, UseQueryResult } from "react-query";
import { useDispatch } from "react-redux";
import { dataImportActions } from "redux/slices/dataImportSlice";

import { RunInfo, RunType } from "types";
import { axios } from "utils";
import { sorter } from "utils/runSorter";

const useGetStartedPrediction = ({
  case_id,
  solution_id,
  run_type,
  training_run_id,
}: {
  case_id: string;
  solution_id: string;
  run_type: RunType;
  training_run_id?: string;
}): UseQueryResult<RunInfo[]> => {
  const dispatch = useDispatch();
  const runDetails = useQuery(
    ["startedPredictionRuns", training_run_id],
    async () => {
      if (case_id !== "" && solution_id !== "" && training_run_id) {
        const { data } = await axios({
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id?.toString()}/solution/${solution_id?.toString()}/run/${run_type}?train_run_id=${training_run_id}`,
        });
        return data
          .filter(
            (e: RunInfo) =>
              e.run_status === "STARTING" || e.run_status === "STARTED"
          )
          .sort(sorter);
      }
      return [];
    },
    {
      onSuccess(response) {
        if (!isEmpty(response))
          dispatch(dataImportActions.setPredictionRunId(response[0].run_id));
      },
      enabled: !!run_type && !!training_run_id,
    }
  );
  return runDetails;
};

export default useGetStartedPrediction;
