import { useQuery, UseQueryResult } from "react-query";

import { Case } from "types";
import { axios } from "utils";

const useGetAllCases = (): UseQueryResult<Case[]> => {
  const data_sources = useQuery("cases", async (): Promise<Case[]> => {
    const { data } = await axios({
      url: process.env.REACT_APP_API + `/core/case`,
    });
    return data;
  });
  return data_sources;
};

export default useGetAllCases;
