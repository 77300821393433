import { Components, listItemButtonClasses } from "@mui/material";

import baseTheme from "theme/baseTheme";

const MuiListItemButton: Components["MuiListItemButton"] = {
	defaultProps: {
		disableRipple: true,
	},
	styleOverrides: {
		root: {
			fontWeight: baseTheme.typography.fontWeightBold,
			"&:hover": {
				backgroundColor: baseTheme.palette.primary.main,
				color: baseTheme.palette.common.white,
				fontWeight: baseTheme.typography.fontWeightBold,
			},
			[`&.${listItemButtonClasses["selected"]}`]: {
				backgroundColor: baseTheme.palette.corn.light,
				color: baseTheme.palette.common.white,
				fontWeight: baseTheme.typography.fontWeightBold,
			},
			[`&.${listItemButtonClasses["selected"]}:hover`]: {
				backgroundColor: baseTheme.palette.primary.main,
			},
		},
	},
};

export default MuiListItemButton;
