import { Components } from "@mui/material";

const MuiTextField: Components["MuiTextField"] = {
	defaultProps: {
		color: "secondary",
		margin: "dense",
		rows: 5,
	},
};

export default MuiTextField;
