import "assets/Loading/Loading.css";

const Icon = (): JSX.Element => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="loading"
			height="36"
			viewBox="0 0 121.221 120.653"
		>
			<g id="Layer_2" data-name="Layer 2" transform="translate(0 -0.033)">
				<g id="BACKGROUND">
					<g id="Group_616" data-name="Group 616" opacity="0.66">
						<path
							id="Path_425-2"
							data-name="Path 425-2"
							d="M114,88.1h0a59.35,59.35,0,0,1-32.89,29l-4.55-14a45.58,45.58,0,0,0,24.13-21.8Z"
							fill="#f3f5fb"
						/>
						<path
							id="Path_426-2"
							data-name="Path 426-2"
							d="M20.29,40.46a45.53,45.53,0,0,0-3.41,32.65L2.55,77.75A59.63,59.63,0,0,1,40.06,4.22l4.66,14.3A45.44,45.44,0,0,0,20.29,40.46Z"
							fill="#f3f5fb"
						/>
						<path
							id="Path_427"
							data-name="Path 427"
							d="M117.31,83,103.8,76.09a45.84,45.84,0,0,0,1-26.57l-2.09-6.13a45.92,45.92,0,0,0-52.49-27L45.49,2A60,60,0,0,1,116.8,38.8l2,6.15A59.62,59.62,0,0,1,117.31,83Z"
							fill="#f3f5fb"
						/>
						<path
							id="Path_428"
							data-name="Path 428"
							d="M75.94,118.53A59.93,59.93,0,0,1,4.18,82.44l14.45-4.69a45.91,45.91,0,0,0,52.71,26.64Z"
							fill="#f3f5fb"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Icon;
