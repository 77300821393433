import { useQuery, UseQueryResult } from "react-query";
import { RunInfo } from "types";
import { axios } from "utils";
import { sorter } from "utils/runSorter";

const useGetSuccessRunsByType = ({
  case_id,
  solution_id,
  run_type,
}: {
  case_id: string;
  solution_id: string;
  run_type: string;
}): UseQueryResult<RunInfo[]> => {
  const runStatus = useQuery(
    ["runStatus", run_type],
    async () => {
      if (case_id !== "" && solution_id !== "") {
        const { data } = await axios({
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id.toString()}/solution/${solution_id.toString()}/run/${run_type}`,
        });
        return data
          .filter((e: RunInfo) => e.run_status === "SUCCESS")
          .sort(sorter);
      } else return [];
    },
    {
      refetchInterval: false, //run_type == "prediction" ? 1000 : false,
    }
  );
  return runStatus;
};

export default useGetSuccessRunsByType;
