import { AxiosResponse } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import jwt_decode from 'jwt-decode'
import { KeycloakResponse } from 'types'
import { axios } from 'utils'
import { useNavigate } from 'react-router-dom'

const AUTH_URL = process.env.REACT_APP_AUTH_API as string

export type Credentials = { username: string; password: string }

export const useSignin = (): UseMutationResult<
  AxiosResponse,
  unknown,
  Credentials,
  unknown
> => {
  const navigate = useNavigate()
  const signin = useMutation(
    async (userInfo: Credentials) => {
      return axios(
        {
          method: 'post',
          data: userInfo,
          url: AUTH_URL as string,
        },
        false
      )
    },
    {
      onSuccess: (response) => {
        localStorage.setItem(
          'userName',
          new URLSearchParams(response.config.data).get('username') as string
        )
        localStorage.setItem(
          'token',
          JSON.stringify(response.data as KeycloakResponse)
        )

        const decodedToken: any = jwt_decode(response.data.access_token)
        if (decodedToken['groups']?.includes('ui-user')) {
          navigate('/')
        } else if (decodedToken['groups']?.includes('api-user')) {
          navigate('/external')
        }
      },
    }
  )
  return signin
}

export default useSignin
