import { AxiosResponse } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { Solution, SolutionWithCaseId } from "types";
import { axios } from "utils";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction } from "react";

export const useSendNewSolution = (
  setSelectedSolution: Dispatch<SetStateAction<Solution>>
): UseMutationResult<
  { solution_id: string; solution_name: string; solution_description: string },
  unknown,
  SolutionWithCaseId,
  unknown
> => {
  const newSolution = useMutation({
    mutationFn: async ({
      case_id,
      solution_name,
      solution_description,
      solutionConfig,
    }: SolutionWithCaseId) => {
      const response: AxiosResponse<{
        solution_id: string;
        solution_name: string;
        solution_description: string;
      }> = await toast.promise(
        axios({
          method: "post",
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id.toString()}/solution`,
          data: {
            solution_name,
            solution_description,
            config: {
              model_dict_name: solutionConfig.model_dict,
              hierarchy_ids: solutionConfig.hierarchy,
            },
          },
        }),
        {
          pending: "Solution uploading...",
          success: "Solution created successfully 👌",
          error: "Solution upload rejected 🤯",
        }
      );

      return response.data;
    },
    onSuccess: (response) => {
      setSelectedSolution({
        solution_id: response?.solution_id,
        solution_name: response?.solution_name,
        solution_description: response?.solution_description,
      });
    },
  });
  return newSolution;
};

export default useSendNewSolution;
