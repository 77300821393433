import { Tooltip } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import useDeleteSolution from "hooks/endpoints/useDeleteSolution";

import { MdDelete, MdOutlineCancel, MdOutlineCheck } from "react-icons/md";
import { Dispatch, SetStateAction, useState } from "react";
import { Solution } from "types";
const initialSolution = {
  solution_id: "",
  solution_name: "",
  solution_description: "",
};
const DeleteSolution = ({
  refetch,
  case_id,
  solution_id,
  setSolutionInfo,
}: {
  refetch: any;
  case_id: string;
  solution_id: string;
  setSolutionInfo: Dispatch<SetStateAction<Solution>>;
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    deleteSelectedSolution();
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { mutateAsync: deleteSolution } = useDeleteSolution();

  const deleteSelectedSolution = async () => {
    const data = { case_id: case_id, solution_id: solution_id };
    await deleteSolution(data);
    refetch();
    setSolutionInfo(initialSolution);
  };
  return (
    <>
      <Tooltip title={"Delete Solution"}>
        <span>
          <MdDelete color="black" onClick={handleIconClick} />
        </span>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>Are you sure?</Typography>
        <MdOutlineCheck />
        <MdOutlineCancel />
      </Popover>
    </>
  );
};
export default DeleteSolution;
