import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { axios } from 'utils';
import { useDispatch } from 'react-redux';
import { dataImportActions } from 'redux/slices/dataImportSlice';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import { RunConfig } from 'types/dataImport.type';
import { RunInfoById } from 'types';
export const useSendTrigger = (
  runType: string,
  setLoading?: Dispatch<SetStateAction<boolean>>
): UseMutationResult<
  { run_id: string },
  unknown,
  {
    case_id: string;
    solution_id: string;
    trainingInfo?: RunInfoById;
    triggerType: string;
    runConfig: RunConfig;
  },
  unknown
> => {
  const dispatch = useDispatch();
  const trigger = useMutation({
    mutationFn: async ({
      trainingInfo,
      case_id,
      solution_id,
      triggerType,
      runConfig,
    }: {
      trainingInfo?: RunInfoById;
      case_id: string;
      solution_id: string;
      triggerType: string;
      runConfig: RunConfig;
    }) => {
      //** TODO : saatlik formatı düzelt*/
      let data = {} as RunConfig;
      if (triggerType === 'finetuning') {
        data['start_date'] = new Date(runConfig.start_date) as any;
        data['end_date'] = new Date(runConfig.end_date) as any;
        data['forecast_horizon'] = 30;
      }

      if (triggerType === 'prediction' && trainingInfo) {
        data['start_date'] = new Date(
          trainingInfo.metadata.training_data_range.start_date
        ) as any;
        data['end_date'] = new Date(
          trainingInfo.metadata.training_data_range.end_date
        ) as any;
        data['forecast_horizon'] = runConfig.forecast_horizon;
      }

      const response: AxiosResponse<{
        run_id: string;
      }> = await toast.promise(
        axios({
          method: 'post',
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id.toString()}/solution/${solution_id.toString()}/run/${triggerType}`,
          data: data,
        }),
        {
          pending:
            triggerType === 'finetuning'
              ? 'Training starting...'
              : 'Prediction starting...',
          success:
            triggerType === 'finetuning'
              ? 'Training started 👌'
              : 'Prediction started 👌',
          error:
            triggerType === 'finetuning'
              ? 'Training error 🤯'
              : 'Prediction error 🤯',
        }
      );
      return response.data;
    },
    onSuccess: (response) => {
      if (runType === 'finetuning')
        dispatch(dataImportActions.setTrainingRunId(response.run_id));
      else if (runType === 'prediction')
        dispatch(dataImportActions.setPredictionRunId(response.run_id));
    },
    onError: () => {
      if (setLoading) setLoading(false);
    },
  });

  return trigger;
};

export default useSendTrigger;
