import { Components } from "@mui/material";

import baseTheme from "theme/baseTheme";

const MuiSlider: Components["MuiSlider"] = {
	styleOverrides: {
		valueLabel: {
			backgroundColor: baseTheme.palette.secondary.main,
			color: baseTheme.palette.common.white,
		},
	},
};

export default MuiSlider;
