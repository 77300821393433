import { Tooltip } from "@mui/material";
import useDeleteCase from "hooks/endpoints/useDeleteCase";
import { MdDelete } from "react-icons/md";

const DeleteCase = ({
  refetchSolution,
  refetch,
  case_id,
}: {
  refetchSolution: any;
  refetch: any;
  case_id: string;
}): JSX.Element => {
  const { mutateAsync: deleteCase } = useDeleteCase();

  const deleteSelectedCase = async (case_id: string) => {
    await deleteCase(case_id);
    refetchSolution();
    refetch();
  };
  return (
    <>
      <Tooltip title={"Delete Case"}>
        <span>
          <MdDelete
            color="black"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              deleteSelectedCase(case_id);
            }}
          />
        </span>
      </Tooltip>
    </>
  );
};
export default DeleteCase;
