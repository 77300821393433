import { ReactComponent as RunLoading } from "assets/Loading/RunLoading.svg";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Grow,
  TextField,
  Tooltip,
} from "@mui/material";
import { addCaseSolutionStyle } from "./Style";
import SwitchSelector from "react-switch-selector";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdInfo } from "react-icons/md";
import { Case, Solution } from "types";
import { dataImportActions } from "redux/slices/dataImportSlice";
import { useDispatch } from "react-redux";
import DeleteSolution from "../DeleteDataComponents/DeleteSolution";
import { LoadingButton } from "@mui/lab";

const solutionSwipeOptions = [
  {
    label: "Select Existing",
    value: false,
    selectedBackgroundColor: "#0097e6",
    innerHeight: 50,
  },
  {
    label: "Add New",
    value: true,
    selectedBackgroundColor: "#fbc531",
  },
];

const initialSolution = {
  solution_id: "",
  solution_name: "",
  solution_description: "",
};

const AddSolution = ({
  isFetchingStartedFinetunings,
  isSolutionExist,
  setActiveStep,
  isFetchingTrainingRunsWithStatus,
  solutions,
  solutionInfo,
  setSolutionInfo,
  refetchSolution,
  caseInfo,
}: {
  isFetchingStartedFinetunings: boolean;
  isSolutionExist: boolean;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isFetchingTrainingRunsWithStatus: boolean;
  solutions: Solution[];
  solutionInfo: Solution;
  setSolutionInfo: Dispatch<SetStateAction<Solution>>;
  refetchSolution: any;
  caseInfo: Case;
}): JSX.Element => {
  const [toggleAddNewSolution, setToggleAddNewSolution] = useState(
    solutions.length > 0 ? false : true
  );
  const [solutionOptions, setSolutionOptions] = useState<Solution[] | null>(
    null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setSolutionOptions(solutions);
  }, [solutions]);

  const onChangeSelectedSolution = (value: Solution | null) => {
    dispatch(dataImportActions.removeTrainingRunId());
    if (value) {
      setSolutionInfo(value as Solution);
    } else {
      setSolutionInfo(initialSolution);
    }
  };

  const handleContinue = () => {
    if (isSolutionExist) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };
  return (
    <Grow in={true} timeout={800}>
      <Box sx={addCaseSolutionStyle}>
        <Grid item xs={9} lg={8} xl={6}>
          <SwitchSelector
            onChange={(e) => {
              setSolutionInfo(initialSolution);
              setToggleAddNewSolution(e as boolean);
            }}
            options={solutionSwipeOptions}
            initialSelectedIndex={solutions.length > 0 ? 0 : 1}
            backgroundColor={"#353b48"}
            fontColor={"#f5f6fa"}
          />
        </Grid>
        {toggleAddNewSolution ? (
          <Grid item xs={9} lg={8} xl={6} mt={4}>
            <TextField
              fullWidth
              variant="standard"
              label="Solution Name"
              helperText={
                solutionInfo.solution_name?.trimEnd() === ""
                  ? "Solution Name should not be empty"
                  : "The name can help you distinguish this solution from other solutions"
              }
              type="text"
              error={solutionInfo.solution_name?.trimEnd() === ""}
              margin="normal"
              onChange={(e) =>
                setSolutionInfo({
                  ...solutionInfo,
                  solution_name: e.target.value,
                })
              }
              value={solutionInfo.solution_name}
            />
            <TextField
              fullWidth
              variant="standard"
              label="Solution Description"
              helperText="Additional information about the solution"
              type="text"
              margin="normal"
              onChange={(e) =>
                setSolutionInfo({
                  ...solutionInfo,
                  solution_description: e.target.value,
                })
              }
              value={solutionInfo.solution_description}
            />
          </Grid>
        ) : (
          <Grid item xs={9} lg={8} xl={6} mt={4}>
            <Autocomplete
              fullWidth
              options={solutionOptions ?? []}
              onChange={(_event, value) =>
                onChangeSelectedSolution(value as Solution)
              }
              getOptionLabel={(option) => option.solution_name}
              isOptionEqualToValue={() => true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  helperText={"Select from existing solutions"}
                  label="Solutions"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.solution_id}>
                  {option.solution_name}
                  <Box ml="auto" color="primary.main">
                    <DeleteSolution
                      refetch={refetchSolution}
                      case_id={caseInfo.case_id}
                      solution_id={option.solution_id}
                      setSolutionInfo={setSolutionInfo}
                    />
                    <Tooltip title={option.solution_description}>
                      <span>
                        <MdInfo />
                      </span>
                    </Tooltip>
                  </Box>
                </li>
              )}
              size="medium"
              value={isSolutionExist ? solutionInfo : null}
            />
          </Grid>
        )}
        <Grid item>
          <LoadingButton
            loading={
              isFetchingStartedFinetunings || isFetchingTrainingRunsWithStatus
            }
            loadingIndicator={<RunLoading />}
            sx={{ mb: 2, mt: 7, minWidth: "100px" }}
            variant={"contained"}
            color={"secondary"}
            disabled={
              solutionInfo.solution_name?.trimEnd() === "" ||
              isFetchingTrainingRunsWithStatus
            }
            onClick={handleContinue}
          >
            Continue
          </LoadingButton>
          <Button
            color="inherit"
            onClick={handleBack}
            sx={{ ml: 5, mb: 2, mt: 7 }}
          >
            Back
          </Button>
        </Grid>
      </Box>
    </Grow>
  );
};
export default AddSolution;
