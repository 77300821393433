import { useState, useEffect, useMemo } from 'react';
import { Alert, Box, useTheme, Link, CircularProgress } from '@mui/material';
import { Chart as ChartType, ChartData, ScatterDataPoint } from 'chart.js';
import { isEmpty, merge } from 'lodash';
import { useIsFetching, useQueryClient } from 'react-query';
import { getTrainAndTestAnnotations } from 'views/Main/Chart/annotations';
import ChartHeader from 'views/Main/Chart/ChartHeader';
import getDatasetLabel from 'views/Main/Chart/getDatasetLabel';
import LineChart from 'views/Main/LineChart';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { useParams } from 'react-router-dom';
import { useGetDataImports, useGetDetailsEntries } from 'hooks';
import saveAs from 'file-saver';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { FaFileCsv, FaImage } from 'react-icons/fa';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorBoundaries';
import { getDecodenToken } from 'utils';

const CHART_DATA_SIZE_LIMIT = parseInt(
  process.env.REACT_APP_CHART_DATA_SIZE_LIMIT || '5000'
);

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

const getActualLabelColor = (numOfBars: number) => {
  let colors = [];
  for (let i = 0; i < numOfBars; i++) {
    let color = 'rgb(';
    color += Math.floor(Math.random() * 50) + ',';
    color += Math.floor(Math.random() * 185) + 70 + ',';
    color += Math.floor(Math.random() * 50) + ')';
    colors.push(color);
  }
  return colors;
};

const getErrorLabelColor = (numOfBars: number) => {
  let colors = [];
  for (let i = 0; i < numOfBars; i++) {
    let color = 'rgb(';
    color += Math.floor(Math.random() * 185) + 70 + ',';
    color += Math.floor(Math.random() * 50) + ',';
    color += Math.floor(Math.random() * 50) + ')';
    colors.push(color);
  }
  return colors;
};

const getRandomColors = (numOfBars: number) => {
  let colors = [];
  for (let i = 0; i < numOfBars; i++) {
    let color = 'rgb(';
    color += Math.floor(Math.random() * 50) + ',';
    color += Math.floor(Math.random() * 50) + ',';
    color += Math.floor(Math.random() * 185) + 70 + ')';
    colors.push(color);
  }
  return colors;
  //random colors:
  // const letters = "0123456789ABCDEF".split("");
  // let colors = [];
  // for (let i = 0; i < numOfBars; i++) {
  //   let color = "#";
  //   for (let k = 0; k < 6; k++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   colors.push(color);
  // }
  // return colors;
};

const Chart = (): JSX.Element => {
  const { case_id } = useParams();
  const role = getDecodenToken();

  const entryId = useSelector((state: RootState) => {
    return state.main.configState.entryId;
  });

  const { data: dataImportsData } = useGetDataImports({ case_id });
  const { data: entryDetails } = useGetDetailsEntries({
    entry_id: entryId ?? null,
  });

  const targetColumn = useMemo(() => {
    return dataImportsData
      ? dataImportsData?.data_metadata?.target_column
      : entryDetails?.target_column;
  }, [dataImportsData, entryDetails]);

  const theme = useTheme();

  const colors = getRandomColors(100);

  const isFetching = useIsFetching(['chartData']);
  const [chartInstance, setChartInstance] = useState<ChartType>();

  const queryClient = useQueryClient();
  const chartData = queryClient.getQueryData<{ id: string; results: any[] }[]>([
    'chartData',
  ]);
  /**when reload , gets config from localstorage and refecthing graph datas */

  const metadata = useSelector(
    (state: RootState) => state.main.configState.runMetadata
  );
  const [dataSizeError, setDataSizeError] = useState(false);

  useEffect(() => {
    if (chartData) {
      setDataSizeError(false);
      chartData.forEach((cd) => {
        if (cd.results.length > CHART_DATA_SIZE_LIMIT) {
          setDataSizeError(true);
        }
      });
    }
  }, [chartData]);

  const [chartjsData, setChartjsData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  const [annotations, setAnnotations] = useState<Record<string, unknown>>({});

  useEffect(() => {
    const isDataGroupPresent = chartData?.find(
      (dataGroup) => !isEmpty(dataGroup.results)
    );
    if (chartData && isDataGroupPresent) {
      const newChartjsData: ChartData<'line'> = { datasets: [] };

      let xMin = Infinity;
      let xMax = 0;
      for (const [dataGroupIndex, dataGroup] of chartData.entries()) {
        const data = dataGroup.results;
        for (const dataUnit of data) {
          for (const key of Object.keys(dataUnit)) {
            const actualColor =
              getActualLabelColor(50)[
                dataGroupIndex % getActualLabelColor(50).length
              ];
            const x = new Date(dataUnit.date).getTime();
            let y;
            if (
              key !== 'date' &&
              key !== 'xdock_id' &&
              key !== 'cluster_name' &&
              !key.includes('anomaly') &&
              key !== 'predictions'
            ) {
              y = dataUnit[key];
              if (y !== '') {
                const label = getDatasetLabel(dataGroup.id, key, targetColumn);
                let dataset = newChartjsData.datasets.find(
                  (dataset) => dataset.label === label
                );
                if (dataset) {
                  dataset.data.push({ x, y });
                } else {
                  dataset = {
                    label,
                    data: [{ x, y }],
                    hidden: false,
                    fill: false,
                    backgroundColor: actualColor,
                    borderColor: actualColor,
                    pointRadius: 1,
                    pointStyle: 'circle',
                    pointHoverRadius: 10,
                    borderWidth: 3,
                  };
                  newChartjsData.datasets.push(dataset);
                }
              }
            }
            if (key === 'predictions') {
              for (const [, option] of dataUnit.predictions.entries()) {
                for (const [, predictionKey] of Object.keys(option).entries()) {
                  const errorColor =
                    getErrorLabelColor(50)[
                      dataGroupIndex % getActualLabelColor(50).length
                    ];
                  const randomNumber = getRandomInt(colors.length);
                  const modelColor = colors[randomNumber % colors.length];
                  if (predictionKey !== 'model_name') {
                    y = option[predictionKey];
                    if (y !== '') {
                      const label = getDatasetLabel(
                        dataGroup.id,
                        {
                          key: predictionKey,
                          model_name: option['model_name'],
                        },
                        targetColumn
                      );
                      let dataset = newChartjsData.datasets.find(
                        (dataset) => dataset.label === label
                      );
                      if (dataset) {
                        dataset.data.push({ x, y });
                      } else {
                        //TODO :  error ve prediction lineları  için burayı düzenle
                        dataset = {
                          label,
                          data: [{ x, y }],
                          hidden: false,
                          fill: false,
                          backgroundColor: label.includes('Error')
                            ? errorColor
                            : modelColor,
                          borderColor: label.includes('Error')
                            ? errorColor
                            : modelColor,
                          pointRadius: 1,
                          borderWidth: label.includes('Error') ? 2 : 1,
                          borderDash: label.includes('Error') ? [5, 5] : [0, 0],
                        };
                        newChartjsData.datasets.push(dataset);
                      }
                    }
                  }
                }
              }
            }
            if (xMax < x) xMax = x;
            if (xMin > x) xMin = x;
          }
        }
      }
      setChartjsData(newChartjsData);
      if (metadata) {
        let annotations: { [key: string]: AnnotationOptions } = {
          ...getTrainAndTestAnnotations(xMin, xMax, metadata, theme),
        };
        setAnnotations({ ...annotations });
      }
    } else setChartjsData({ datasets: [] });
  }, [chartData, metadata, targetColumn]);

  const handleSaveChart = () => {
    const canvasSave = document.getElementById('2') as HTMLCanvasElement;
    canvasSave.toBlob(function (blob) {
      saveAs(blob as Blob, `Chart.png`);
    });
  };

  const generateCSVData = () => {
    const csvData: { [key: string]: unknown }[] = [];
    const datasets = chartInstance?.config.data.datasets;
    if (datasets)
      for (const [datasetIndex, dataset] of datasets?.entries()) {
        if (chartInstance?.isDatasetVisible(datasetIndex)) {
          const newDataset = dataset?.data.map((dataUnit) => ({
            Date: format((dataUnit as ScatterDataPoint).x, 'yyyy MMM dd'),
            [dataset.label as string]: (dataUnit as ScatterDataPoint).y,
          }));
          merge(csvData, newDataset);
        }
      }
    return csvData;
  };

  return (
    <>
      {role['data-import-enabled'] ? (
        <Box mb={1}>
          <ChartHeader />
        </Box>
      ) : undefined}

      {isFetching ? (
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      ) : (
        !dataSizeError &&
        chartjsData.datasets.length > 0 && (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <LineChart
              chartInstance={chartInstance}
              setChartInstance={setChartInstance}
              chartData={
                chartData as {
                  id: string;
                  results: any[];
                }[]
              }
              chartjsData={chartjsData}
              annotations={annotations}
              setAnnotations={setAnnotations}
            />
          </ErrorBoundary>
        )
      )}
      {chartInstance && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Link>
            <FaImage
              size={'2em'}
              style={{ marginRight: '15' }}
              onClick={() => {
                handleSaveChart();
              }}
            />
          </Link>
          <CSVLink
            data={generateCSVData()}
            filename={'Chart'}
            style={{
              color: theme.palette.bittersweet.dark,
            }}
          >
            <FaFileCsv style={{ marginRight: '15' }} size={'2em'} />
          </CSVLink>
        </Box>
      )}
      {dataSizeError && (
        <Box width='100%'>
          <Alert severity='info'>
            The data length to be displayed is too long. It should be continued
            by adding a filter to the data source.
          </Alert>
        </Box>
      )}
    </>
  );
};

export default Chart;
