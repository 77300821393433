import { Theme } from "@mui/material";
import { AnnotationOptions } from "chartjs-plugin-annotation";

const getTrainAndTestAnnotations = (
  xMin: number,
  xMax: number,
  metadata: {
    description: string;
    train_start_date: string | null;
    train_end_date: string | null;
  },
  theme: Theme
): { [key: string]: AnnotationOptions } => {
  let annotations: { [key: string]: AnnotationOptions } = {};

  if (metadata.train_start_date) {
    if (xMax <= new Date(metadata.train_end_date ?? 1).getTime()) {
      annotations = {
        train: {
          type: "box",
          xMin,
          xMax,
          yMin: (ctx) => ctx.chart.scales.y.min,
          yMax: (ctx) => ctx.chart.scales.y.max,
          backgroundColor: theme.palette.white.main + "33",
          borderWidth: 0,
        },
      };
    } else if (new Date(metadata?.train_end_date ?? 1).getTime() < xMin) {
      annotations = {
        test: {
          type: "box",
          xMin,
          xMax,
          yMin: (ctx) => ctx.chart.scales.y.min,
          yMax: (ctx) => ctx.chart.scales.y.max,
          backgroundColor: theme.palette.bittersweet.light + "33",
          borderWidth: 0,
        },
      };
    } else {
      annotations = {
        train: {
          type: "box",
          xMin,
          xMax: new Date(metadata.train_end_date ?? 1).getTime(),
          yMin: (ctx) => ctx.chart.scales.y.min,
          yMax: (ctx) => ctx.chart.scales.y.max,
          backgroundColor: theme.palette.blue.light + "33",
          borderWidth: 0,
        },
        test: {
          type: "box",
          xMin: new Date(metadata.train_end_date ?? 1).getTime(),
          xMax,
          yMin: (ctx) => ctx.chart.scales.y.min,
          yMax: (ctx) => ctx.chart.scales.y.max,
          backgroundColor: theme.palette.bittersweet.light + "33",
          borderWidth: 0,
        },
      };
    }
  }

  return annotations;
};

export default getTrainAndTestAnnotations;
