import { AnnotationOptions } from "chartjs-plugin-annotation";

const getAnomalyAnnotations = (
	chartData: {
		id: string;
		results: any[];
	}[]
): { [key: string]: AnnotationOptions } => {
	const annotations: { [key: string]: AnnotationOptions } = {};

	for (const dataGroup of chartData) {
		const data = dataGroup.results;
		for (const dataUnit of data) {
			const anomalyKey = Object.keys(dataUnit).find((key) =>
				key.includes("anomaly")
			);
			if (dataUnit.date && anomalyKey && dataUnit[anomalyKey]) {
				const xDateCoord = new Date(dataUnit.date).getTime();
				annotations["anomaly" + xDateCoord] = {
					//	Indicates the type of annotation
					display: true,
					type: "line",
					xMin: xDateCoord,
					xMax: xDateCoord,
					yMin: (ctx) => ctx.chart.scales.y.min,
					yMax: (ctx) => ctx.chart.scales.y.max,
					borderColor: "black",
					borderDash: [5, 5],
					borderWidth: 1,
				};
			}
		}
	}

	return annotations;
};

export default getAnomalyAnnotations;
