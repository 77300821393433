import {
  Box,
  LinearProgress,
  Link,
  List,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";
import { notificationActions, stepperActions } from "redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import Papa from "papaparse";
import { FETCH_STATUS } from "types/notification.type";
import { dataImportActions } from "redux/slices/dataImportSlice";
import { MdDelete } from "react-icons/md";
import { useCallback, useState } from "react";
function Dropzone({
  accept,
  isSideInfo,
}: {
  accept: string;
  isSideInfo: boolean;
}): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [inputLoadingProgress, setInputLoadingProgress] = useState(false);

  const actualFile = useSelector(
    (state: RootState) => state.dataImport.actualFile.file
  );
  const { sideInfoFiles } = useSelector((state: RootState) => state.stepper);
  // const options = useSelector(
  //   (state: RootState) => state.stepper.sideInfoDataFileDropdownInfos.options
  // );

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!isSideInfo) {
      setInputLoadingProgress(true);
      if (actualFile) {
        dispatch(
          notificationActions.setProcessInfo({
            error: "You can upload only 1 actual data file",
            status: FETCH_STATUS.ERROR,
          })
        );
      }
      Papa.parse(acceptedFiles[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          const columns = Object.keys(results.data[0]);
          dispatch(stepperActions.setActualDataFileDropdownInfos(columns));
          setInputLoadingProgress(false);
        },
      });
      dispatch(dataImportActions.setActualFile(acceptedFiles[0]));
    } else {
      let isIncluded = false;
      for (const file of sideInfoFiles.files) {
        if (file.name === acceptedFiles[0].name) {
          isIncluded = true;
        }
      }
      if (!isIncluded) {
        acceptedFiles.forEach((file) => {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: any) {
              const columns = Object.keys(results.data[0]);
              dispatch(stepperActions.setSideInfoDropdownInfoOptions(columns));
              dispatch(
                stepperActions.setSideInfoDropdownInfoFileNames(file.name)
              );
            },
          });
        });

        dispatch(
          stepperActions.setSideInfoFilesState([
            ...sideInfoFiles.files,
            ...acceptedFiles,
          ])
        );
      } else {
        dispatch(
          notificationActions.setProcessInfo({
            error: "File already uploaded",
            status: FETCH_STATUS.ERROR,
          })
        );
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept,
      onDrop,
    });

  const deleteFile = useCallback((isActual: boolean, file?: File) => {
    if (isActual) {
      dispatch(dataImportActions.removeActualFile());
      if (file) {
        setInputLoadingProgress(true);
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results: any) {
            const columns = Object.keys(results.data[0]);
            dispatch(stepperActions.removeActualColumns(columns));
            setInputLoadingProgress(false);
          },
        });
      }
    } else {
      if (file) {
        dispatch(stepperActions.removeSideInfoFile(file));
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results: any) {
            const columns = Object.keys(results.data[0]);
            dispatch(stepperActions.removeSideInfoColumns(columns));
          },
        });
      }
    }
  }, []);
  return (
    <Box margin={1}>
      <Box
        {...getRootProps()}
        border={`2px dashed ${theme.palette.corn.main}`}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={"30vh"}
        maxWidth={700}
        sx={{ cursor: "pointer" }}
      >
        <input {...getInputProps()} />
        {isDragReject ? (
          <Typography variant="subtitle1">
            Please use excel or csv files
          </Typography>
        ) : isDragActive ? (
          <Typography variant="subtitle1">Drop the files here</Typography>
        ) : !actualFile ? (
          <Typography variant="subtitle1" align="center">
            Drag and drop the file here, or click to select the file.
            <br></br>
            Only <b>csv</b> file type is supported
            <br></br>
            If the file contains rows that are already in the system, the values
            will be <b> overwritten</b>.
          </Typography>
        ) : (
          <Typography
            sx={{ color: "red", fontWeight: "bold", fontSize: "large" }}
            variant="subtitle1"
            align="center"
          >
            1 File Uploaded
          </Typography> //**TODO : */
        )}
      </Box>
      <br />

      {isSideInfo && !isEmpty(sideInfoFiles.files) && (
        <>
          <span>Click file name to delete</span>
          <List>
            {sideInfoFiles.files.map((file, index) => (
              <ListItemText
                key={index}
                onClick={() => deleteFile(false, file)}
                primary={`${file.name} - ${file.size} bytes`}
                primaryTypographyProps={{ variant: "caption" }}
              />
            ))}
          </List>
        </>
      )}
      {inputLoadingProgress && (
        <>
          {"Loading..."}
          <LinearProgress color="secondary" />
        </>
      )}
      {!isSideInfo && actualFile && !inputLoadingProgress && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          sx={{ alignItems: "center" }}
        >
          <List>
            <ListItemText
              primary={`${actualFile.name} - ${actualFile.size} bytes`}
              primaryTypographyProps={{ variant: "caption" }}
            />
          </List>
          <Tooltip title="Delete File">
            <Link ml={1} mb={0.5}>
              <MdDelete
                size={"1.2em"}
                onClick={() => deleteFile(true, actualFile)}
              />
            </Link>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}

export default Dropzone;
