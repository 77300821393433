import { Popconfirm } from 'antd'
import {
  Box,
  FormHelperText,
  FormControl,
  Grid,
  Grow,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import HeaderDiv from 'components/HeaderDiv'
import { format } from 'date-fns'
import { useSendTrigger } from 'hooks'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dataImportActions } from 'redux/slices/dataImportSlice'
import { RootState } from 'redux/store'
import { DateRangePicker } from 'rsuite'
import { Case, RunInfo, RunInfoById, Solution } from 'types'
import CustomizedRadios from './CustomizedRadios'
import { ReactComponent as RunLoading } from 'assets/Loading/RunLoading.svg'

import { isEmpty } from 'lodash'

const StartTraining = ({
  setActiveStep,
  trainingInfo,
  loadingContinue,
  finetuningRunning,
  useMostRecentTraining,
  setUseMostRecentTraining,
  newTrainingFinished,
  setNewTrainingFinished,
  trainingRunsWithStatus,
  caseInfo,
  solutionInfo,
  loading,
  setLoading,
}: {
  setActiveStep: Dispatch<SetStateAction<number>>
  trainingInfo?: RunInfoById
  loadingContinue: boolean
  finetuningRunning: boolean
  useMostRecentTraining: boolean
  setUseMostRecentTraining: Dispatch<SetStateAction<boolean>>
  newTrainingFinished: boolean | null
  setNewTrainingFinished: Dispatch<SetStateAction<null | boolean>>
  caseInfo: Case
  solutionInfo: Solution
  trainingRunsWithStatus: RunInfo[] | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}): JSX.Element => {
  const dispatch = useDispatch()

  const [radioButtonLabel2, setRadioButtonLabel2] = useState<string | null>(
    null
  )
  const [selectedRadio, setSelectedRadio] = useState('newTraining')

  const { mutateAsync: sendTrigger, isLoading } = useSendTrigger(
    'finetuning',
    setLoading
  )
  const { runConfig } = useSelector((state: RootState) => state.dataImport)
  const { trainingDates } = useSelector((state: RootState) => state.stepper)

  useEffect(() => {
    if (trainingRunsWithStatus && trainingRunsWithStatus.length > 0) {
      if (finetuningRunning) setSelectedRadio('newTraining')
      else setSelectedRadio('mostRecentTraining')
      setRadioButtonLabel2(
        `Use the Most Recent Training ( Training Date : ${format(
          new Date(trainingRunsWithStatus[0].created_at),
          'dd-MM-yyyy HH:mm:ss'
        )})`
      )
    } else {
      setSelectedRadio('newTraining')
      setRadioButtonLabel2(null)
    }
  }, [trainingRunsWithStatus, finetuningRunning])

  useEffect(() => {
    if (
      useMostRecentTraining &&
      trainingRunsWithStatus &&
      trainingRunsWithStatus.length > 0
    ) {
      if (!finetuningRunning)
        dispatch(
          dataImportActions.setTrainingRunId(trainingRunsWithStatus[0].run_id)
        )
    }
  }, [useMostRecentTraining, trainingRunsWithStatus])

  const handleTriggers = async (runType: string) => {
    setLoading(true)
    setNewTrainingFinished(false)
    await sendTrigger({
      case_id: caseInfo.case_id,
      solution_id: solutionInfo.solution_id,
      triggerType: runType,
      runConfig,
    })
  }

  const onChangeDate = (dates: [start: Date, end: Date] | null) => {
    if (dates) {
      const [start, end] = dates

      dispatch(
        dataImportActions.setRunStartEndDate({
          start_date: format(new Date(start), 'yyyy-MM-dd 00:00:00'),
          end_date: format(new Date(end), 'yyyy-MM-dd 00:00:00'),
        })
      )
    }
  }

  const onCleanDate = () => {
    dispatch(
      dataImportActions.setRunStartEndDate({
        start_date: trainingDates.default_start_date.toISOString(),
        end_date: trainingDates.default_end_date.toISOString(),
      })
    )
  }

  const [defaultCalenderValue, setDefaultCalendarValue] = useState(new Date())
  useEffect(() => {
    setDefaultCalendarValue(trainingDates.default_start_date)
  }, [trainingDates])

  const handleContinue = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2)
  }

  return (
    <Grow in={true} timeout={800}>
      <Grid container>
        <CustomizedRadios
          pipelineStarted={loading || finetuningRunning}
          label1={'Start New Training'}
          label2={radioButtonLabel2}
          values={['newTraining', 'mostRecentTraining']}
          value={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          setState={setUseMostRecentTraining}
          runType={'finetuning'}
        />
        <Grid item xs={12} md={12} lg={8} xl={8}>
          {!useMostRecentTraining && (
            <Grow in={true} timeout={800}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl
                  sx={{
                    mt: 2,
                  }}
                >
                  <HeaderDiv title={'Start Training'} />
                  <DateRangePicker
                    defaultCalendarValue={[
                      defaultCalenderValue,
                      defaultCalenderValue,
                    ]}
                    format='yyyy-MM-dd'
                    placement='bottom'
                    onClean={onCleanDate}
                    defaultValue={[
                      trainingDates.default_start_date,
                      trainingDates.default_end_date,
                    ]}
                    onOk={onChangeDate}
                    appearance='default'
                    placeholder='Default'
                    style={{ marginTop: 30 }}
                    disabledDate={(date) =>
                      !moment(date).isBetween(
                        trainingDates.default_start_date,
                        trainingDates.default_end_date
                      ) &&
                      !moment(date).isSame(
                        trainingDates.default_start_date as Date
                      ) &&
                      !moment(date).isSame(
                        trainingDates.default_end_date as Date
                      )
                    }
                  />
                  <FormHelperText>
                    Select start & end date for training interval
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ mt: 3.5 }}>
                  {!isEmpty(trainingRunsWithStatus) ? (
                    <Popconfirm
                      placement='top'
                      title={
                        'Already have a training , are you sure to start another ? '
                      }
                      onConfirm={() => handleTriggers('finetuning')}
                      okText='Yes'
                      cancelText='No'
                    >
                      <LoadingButton
                        fullWidth
                        loadingIndicator={<RunLoading />}
                        loading={loading || finetuningRunning}
                        variant={'contained'}
                        size='large'
                      >
                        Start Training
                      </LoadingButton>
                    </Popconfirm>
                  ) : (
                    <LoadingButton
                      fullWidth
                      loadingIndicator={<RunLoading />}
                      loading={loading || finetuningRunning}
                      onClick={() => handleTriggers('finetuning')}
                      variant={'contained'}
                      size='large'
                    >
                      Start Training
                    </LoadingButton>
                  )}
                  <FormHelperText>
                    Training can take up to 40 minutes depending on the training
                    duration selected
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grow>
          )}
          <LoadingButton
            loading={loadingContinue}
            loadingIndicator={<RunLoading />}
            sx={{ mb: 2, mt: 7, minWidth: '100px' }}
            variant={'contained'}
            color={'secondary'}
            disabled={
              !(
                useMostRecentTraining ||
                (newTrainingFinished && trainingInfo?.run_status === 'SUCCESS')
              )
            }
            onClick={handleContinue}
          >
            Continue
          </LoadingButton>
          <Button
            disabled={isLoading}
            color='inherit'
            onClick={handleBack}
            sx={{ ml: 5, mb: 2, mt: 7 }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Grow>
  )
}
export default StartTraining
