import { AxiosResponse } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { axios } from "utils";

export const useDeleteSolution = (): UseMutationResult<
  { solution_id: string },
  unknown,
  {
    case_id: string;
    solution_id: string;
  },
  unknown
> => {
  const deleteSolution = useMutation({
    mutationFn: async ({
      case_id,
      solution_id,
    }: {
      case_id: string;
      solution_id: string;
    }) => {
      const response: AxiosResponse<{ solution_id: string }> = await axios({
        method: "DELETE",
        url:
          process.env.REACT_APP_API +
          `/core/case/${case_id.toString()}/solution/${solution_id.toString()}`,
      });
      return response.data;
    },
  });

  return deleteSolution;
};

export default useDeleteSolution;
