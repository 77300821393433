import { Components } from "@mui/material";

const MuiSnackbar: Components["MuiSnackbar"] = {
	defaultProps: {
		anchorOrigin: { horizontal: "right", vertical: "bottom" },
		autoHideDuration: 1500,
	},
};

export default MuiSnackbar;
