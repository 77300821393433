import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function CustomizedRadios(props: any) {
  const {
    pipelineStarted,
    label1,
    label2,
    values,
    value,
    setState,
    setSelectedRadio,
    runType,
  } = props;
  const [value1, value2] = values;

  useEffect(() => {
    setState(value === value1 ? false : true);
  }, [value]);

  return (
    <FormControl>
      <RadioGroup
        value={value}
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        onChange={(_e, value) => {
          setSelectedRadio(value);
          setState(value === value1 ? false : true);
        }}
      >
        <FormControlLabel
          value={value1} //"newTraining"
          control={<BpRadio />}
          label={label1}
        />
        <FormControlLabel
          value={value2} //"mostRecentTraining"
          control={<BpRadio />}
          disabled={pipelineStarted || (label2 ? false : true)}
          label={
            <div style={{ color: label2 ? "red" : "#9999A1" }}>
              {label2 ??
                (runType === "finetuning"
                  ? "Use the Most Recent Training ( Training Date : None )"
                  : "Use the Most Recent Prediction ( Prediction Date : None )")}
            </div>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
