import { useQuery, UseQueryResult } from 'react-query';
import { IChartconfigurationState } from 'types/main.type';
import { axios, getDecodenToken } from 'utils';

const useFetchChartData = (
  chartConfigurationState: IChartconfigurationState
): UseQueryResult<{ id: string; results: any[] }[]> => {
  const role = getDecodenToken();
  const dataSources = useQuery(
    ['chartData'],
    async () => {
      const { data } = await axios({
        method: 'post',
        url: role['data-import-enabled']
          ? process.env.REACT_APP_API + `/core/query`
          : process.env.REACT_APP_API_EXTERNAL + `/external_api/query`,
        data: {
          filters: chartConfigurationState.filters,
          groups: chartConfigurationState.dataGroups,
        },
      });
      return data;
    },
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: chartConfigurationState.dataGroups !== null,
      refetchInterval: getDecodenToken()['data-import-enabled']
        ? false
        : 5 * 60 * 1000,
    }
  );
  return dataSources;
};
export default useFetchChartData;
