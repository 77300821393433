import { Chart as ChartType } from "chart.js";

const customBackgroundPlugin = {
	id: "custom_canvas_background_color",
	beforeDraw: (chart: ChartType): void => {
		const ctx = chart.canvas.getContext("2d");
		ctx!.save();
		ctx!.globalCompositeOperation = "destination-over";
		ctx!.fillStyle = "white";
		ctx!.fillRect(0, 0, chart.width, chart.height);
		ctx!.restore();
	},
};

export default customBackgroundPlugin;
