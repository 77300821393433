import {
  ActualFileDataColumn,
  IActualDataMetadata,
  IActualFile,
} from "types/dataImport.type";

export const FileColumnValidation = (
  actualFileDataMetadata: IActualDataMetadata,
  actualDataColumns: ActualFileDataColumn,
  actualFile: IActualFile
) => {
  if (!actualFile.file) {
    return true;
  }

  if (
    actualDataColumns.date_column !== null &&
    actualDataColumns.date_column !== "" &&
    actualDataColumns.hierarchy_column !== null &&
    actualDataColumns.hierarchy_column !== "" &&
    actualDataColumns.target_column !== null &&
    actualDataColumns.target_column !== "" &&
    actualFileDataMetadata.date_format !== "" &&
    actualFileDataMetadata.time_frequency !== ""
  )
    return false;

  return true;
};

export default FileColumnValidation;
