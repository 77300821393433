import { useQuery, UseQueryResult } from 'react-query';
import { mainActions } from 'redux/slices';

import { axios, getDecodenToken } from 'utils';

const useGetPredictionMetadata = ({
  case_id,
  solution_id,
  dispatch,
}: {
  case_id: string | undefined;
  solution_id: string | undefined;
  dispatch: any;
}): UseQueryResult<any> => {
  const role = getDecodenToken();
  const predictionMetadata = useQuery(
    'predictionMetadata',
    async () => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/core/case/${case_id?.toString()}/solution/${solution_id?.toString()}`,
      });
      return data.metadata.prediction;
    },
    {
      enabled: role['data-import-enabled'],
      onSuccess: (response) => {
        dispatch(
          mainActions.setLayerData({
            layerData: response.tree.run_tree,
          })
        );
      },
    }
  );
  return predictionMetadata;
};

export default useGetPredictionMetadata;
