import { LinearProgressProps, Box, LinearProgress } from "@mui/material";
import { Typography } from "antd";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress color="secondary" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 100 }}>
        <Typography>{`${Math.round(props.value * 100) / 100}%`}</Typography>
      </Box>
    </Box>
  );
}
export default LinearProgressWithLabel;
