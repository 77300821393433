import { configureStore } from "@reduxjs/toolkit";
import { mainReducer } from "../slices/mainSlice";
import commonReducer from "../slices/commonSlice";
import { notificationInterceptorMiddleware } from "../middleware/notificationInterceptorMiddleware";
import { notificationReducer } from "../slices/notificationSlice";
import { stepperReducer } from "../slices/stepperSlice";
import { dataImportReducer } from "redux/slices/dataImportSlice";
const store = configureStore({
  reducer: {
    main: mainReducer,
    common: commonReducer,
    notification: notificationReducer,
    stepper: stepperReducer,
    dataImport: dataImportReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    notificationInterceptorMiddleware,
  ],
});
export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
