import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import AppBar from "layouts/Main/AppBar";
import Toolbar from "layouts/Main/Toolbar";

export const drawerWidth = 400;

const Main = (): JSX.Element => {
  return (
    <>
      <Box display="flex">
        <AppBar>
          <Toolbar />
        </AppBar>
        <Outlet />
      </Box>
    </>
  );
};

export default Main;
