import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import jwt_decode from 'jwt-decode'

import { checkToken } from 'utils'

const authorizedInstance = axios.create()
authorizedInstance.interceptors.request.use(
  (config) => {
    console.log(config)
    const token = checkToken()
    if (token) {
      const user_info = jwt_decode(token.access_token)
      if (user_info) {
        config.headers['X-Route-To'] = (user_info as { route: 'string' }).route
        config.headers['Authorization'] = 'Bearer ' + token.access_token
      }
    } else {
      return Promise.reject('You are not authorized!')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const instanceForSigIn = axios.create()
instanceForSigIn.interceptors.request.use(
  (config) => {
    config.headers['X-Route-To'] = process.env.REACT_APP_X_ROUTE_TO
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const instance = (
  { ...config }: AxiosRequestConfig,
  authorized = true
): Promise<AxiosResponse> => {
  if (authorized) {
    return authorizedInstance({
      ...config,
    })
  } else {
    return instanceForSigIn({
      ...config,
    })
  }
}

export default instance

export const refreshInstance = ({
  ...config
}: AxiosRequestConfig): Promise<AxiosResponse> =>
  instanceForSigIn({
    ...config,
  })
