import { ListItem } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from 'redux/slices';
import { RootState } from 'redux/store';
import { CheckPicker } from 'rsuite';
import { IEnrtyDetails } from 'types/main.type';

export const EntryLayers = ({
  entryDetails,
}: {
  entryDetails: IEnrtyDetails;
}) => {
  const dispatch = useDispatch();

  const selectionData = useMemo(
    () =>
      entryDetails?.spatial_column_vals?.map((item: any) => ({
        label: item,
        value: item,
      })),
    [entryDetails]
  );

  const entryId = useSelector(
    (state: RootState) => state.main.configState.entryId
  );

  useEffect(() => {
    const elements = [] as any;
    entryDetails?.spatial_column_vals.forEach((layer) => {
      elements.push({
        id: layer,
        datasource: {
          forecast_table_id: entryId,
          value: layer,
        },
      });
    });

    if (isEmpty(elements))
      dispatch(mainActions.setChartConfigurationDataGroup(null));
    else dispatch(mainActions.setChartConfigurationDataGroup(elements));
  }, [entryDetails]);

  const onChangeLayers = (value: string[]) => {
    // Sync selected elements with cascader's state
    const newSelectedElements = [] as any;
    value?.forEach((layer) => {
      newSelectedElements.push({
        id: layer,
        datasource: {
          forecast_table_id: entryId,
          value: layer,
        },
      });
    });
    dispatch(mainActions.setChartConfigurationDataGroup(newSelectedElements));
  };
  return (
    <ListItem>
      <CheckPicker
        onChange={onChangeLayers}
        label='Layers'
        data={selectionData}
        style={{ width: 300 }}
      />
    </ListItem>
  );
};
