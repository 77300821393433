import { SolutionConfig } from 'types/dataImport.type'

export type Layer = {
  label: string
  layer_id: number
  value: string
  children?: Layer[]
  model_explanations: any[]
}

export type Filter = {
  name: string
  value: string
  type: 'string-equals' | 'number-interval' | 'date-interval'
  minVal?: number
  maxVal?: number
}

export type DataHierarchy =
  | {
      layer_id: number
      layer_label: string
      children: DataHierarchy | Record<string, never>
    }
  | Record<string, never>

export type DataGroup = {
  id: string
  groupName: string
  datasource:
    | { name: string; layer: number; value: string }
    | Record<string, never>
  filters: { filter_type: string; filter_value: unknown; operator: string }[]
}

export type KeycloakResponse = {
  access_token: string
  expires_in: number
  refresh_expires_in: number
  refresh_token: string
}
export type Case = {
  case_id: string
  case_name: string
  case_description: string
}
export type Cases = Case[]

export type CaseColumnType = {
  field_name: string
  field_type: string
}

export type CaseColumnTypes = CaseColumnType[]

export type Solution = {
  solution_id: string
  solution_name: string
  solution_description: string
}

export type SolutionWithCaseId = {
  case_id: string
  solution_id: string
  solution_name: string
  solution_description: string
  solutionConfig: SolutionConfig
}
export type Solutions = Solution[]

export type SolutionWithRuns = {
  solution_id: string
  runs: {
    run_id: string
    run_type: string
    created_at: string
    description: string
  }[]
}

export type RunExplanations = {
  cluster_name: string
  model_name: string
  model_run_type: string
  related_operation_id: number
  start_date: string
  end_date: string
}[]

export type ColumnInfo = {
  column_name: string
  column_type_id: number
  date_format: string
  time_frequency: string
}
export type Operation = {
  description: string
  operation_id: string
  operation_type: string
}

export type RunType = 'finetuning' | 'prediction'
export type RunStatus =
  | 'SUCCESS'
  | 'FAILURE'
  | 'STARTED'
  | 'CANCELING'
  | 'CANCELED'
  | 'STARTING'
export type RunInfoById = {
  created_at: string
  metadata: {
    description: string
    training_data_range: {
      end_date: string
      start_date: string
    }
    prediction_data_range: {
      end_date: string
      start_date: string
    }
  }
  operations: Operation[]
  run_id: string
  run_status: RunStatus
  run_type: RunType
  train_run_id: null | string
  tree: {
    run_tree: Layer[]
  }
  updated_at: string
}
export type RunInfo = {
  created_at: string
  run_id: string
  run_status: RunStatus
  run_type: RunType
  train_run_id: null | string
  updated_at: string
}

export type IDataImport = {
  case_id: number
  created_at: string
  data_import_id: number
  data_metadata: {
    data_end_date: string
    data_start_date: string
    target_column: string
    hierarchy_column: string[]
    hierarchy_ids: string[]
    date_column: string
    feature_columns: string[]
    date_format: string
    time_frequency: string
  }
  file_path: string
  is_side_info: boolean
  updated_at: string
}

export enum DataImportSteps {
  AddCase,
  UploadData,
  AddSolution,
  UploadSolutionConfig,
  StartTraining,
  StartPrediction,
}
