import { AxiosResponse } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { IActualDataMetadata } from 'types/dataImport.type'
import { ActualFileDataColumn } from 'types/dataImport.type'
import { axios } from 'utils'
import { useDispatch } from 'react-redux'
import { stepperActions } from 'redux/slices'
import { dataImportActions } from 'redux/slices/dataImportSlice'
import { toast } from 'react-toastify'
import { Dispatch, SetStateAction } from 'react'
export type FilesDescription = {
  is_side_info: boolean
  case_id: string
  file: File
}

export const useSendFiles = (
  actualFileDataMetadata: IActualDataMetadata,
  setProgress: Dispatch<SetStateAction<number>>,
  actualDataColumns: ActualFileDataColumn,
  setFileUploadLoading: Dispatch<SetStateAction<boolean>>
): UseMutationResult<AxiosResponse, unknown, FilesDescription, unknown> => {
  const dispatch = useDispatch()
  const sendFile = useMutation(
    async ({ is_side_info, case_id, file }: FilesDescription) => {
      try {
        const formData = new FormData()
        formData.append(`is_side_info`, is_side_info ? 'true' : 'false')
        formData.append(`file`, file, file.name)
        formData.append(
          `target_column`,
          actualDataColumns.target_column as string
        )
        formData.append(
          `hierarchy_column`,
          actualDataColumns.hierarchy_column as string
        )
        formData.append(`date_column`, actualDataColumns.date_column as string)
        actualDataColumns.feature_columns &&
          formData.append(
            `feature_columns`,
            actualDataColumns.feature_columns.join(',')
          )

        formData.append(
          'date_format',
          actualFileDataMetadata.date_format?.trimStart().trimEnd() as string
        )
        formData.append(
          'time_frequency',
          actualFileDataMetadata.time_frequency as string
        )
        return toast.promise(
          axios({
            method: 'post',
            data: formData,
            url:
              process.env.REACT_APP_API +
              `/core/case/${case_id.toString()}/data_import`,
          }),
          {
            pending: 'File uploading...',
            success: 'File uploaded successfully 👌',
            error: {
              render({ data }: any) {
                return (
                  <>
                    {'File upload rejected. \t ' +
                      data?.response.data.Error +
                      '🤯'}
                  </>
                )
              }, //},
            },
          }
        )
      } catch (e: any) {
        return e
      }
    },
    {
      onSuccess: (response) => {
        setProgress(100)
        setFileUploadLoading(false)
        dispatch(
          stepperActions.setHierarchyDropdown(
            response.data.data_metadata.hierarchy_ids
          )
        )
        dispatch(
          dataImportActions.setRunStartEndDate({
            start_date: response.data.data_metadata.data_start_date,
            end_date: response.data.data_metadata.data_end_date,
          })
        )
        dispatch(
          stepperActions.setTrainingDates({
            default_start_date: new Date(
              response.data.data_metadata.data_start_date as Date
            ),
            default_end_date: new Date(
              response.data.data_metadata.data_end_date as Date
            ),
          })
        )
      },
      onError: (response) => {
        setProgress(0)
        console.log(response)
        setFileUploadLoading(false)
      },
    }
  )

  return sendFile
}

export default useSendFiles
