import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { isEmpty } from "lodash";

import { TextLists, default as lists } from "views/Help/textLists";

const RecursiveList = ({
  lists,
  level,
}: {
  lists: TextLists;
  level: number;
}): JSX.Element => {
  return (
    <>
      {lists.map((list) =>
        !isEmpty(list.children) ? (
          <List sx={{ marginLeft: level * 2 }} key={list.header} dense>
            {list.header && (
              <ListSubheader sx={{ fontWeight: "bold" }}>
                {list.header}
              </ListSubheader>
            )}
            {list.text && <ListItemText>&#8226; {list.text}</ListItemText>}
            <RecursiveList lists={list.children} level={level + 1} />
          </List>
        ) : (
          <ListItem key={list.text}>
            {list.text && <ListItemText>&#8226; {list.text}</ListItemText>}
            <RecursiveList lists={list.children} level={level + 1} />
          </ListItem>
        )
      )}
    </>
  );
};

const Help = (): JSX.Element => {
  return (
    <Container sx={{ mt: 8 }}>
      <RecursiveList lists={lists} level={0} />
    </Container>
  );
};

export default Help;
