import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  padding: theme.spacing(0),
  marginTop: 5,
  fontSize: "20px",
  color: "#3f51b5",
  fontWeight: "600",
}));

export default function HeaderDiv({ title }: { title: string }) {
  return <Div>{title}</Div>;
}
