import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  Dispatch,
  ReactElement,
} from "react";

import DOMPurify from "dompurify";
import { startCase } from "lodash";
import PubNub from "pubnub";

import keyConfiguration from "config/pubnub-keys.json";

const generatedName: string = generateName(); // This is the UUID that we use for identification.

//This is where you define the chat app properties.
export const liveChatState: LiveChatState = {
  presence: true, // Enable or disable presence.
  presenceLastUpdated: 0, // Last time that a presence event was used to update the activeUsers list. Used to prevent duplicate events from triggering multiple calls to hereNow.
  history: false, // Enable or disable history.
  historyMax: 10, // How many messages to load from history (max 100).
  maxMessagesInList: 200, // Max number of messages at most in the message list.
  selfName: generatedName, // Set the display name to be the same as the UUID. You can make this whatever you want.
  message: "",
  messages: [
    {
      message: "What can I help you with today?",
      senderName: "Predy Support",
    },
  ], // Array of UserMessages. - In support chat we preload with a message to prompt the user to respond.
  activeUsers: [], // Array of active users.
  channel: "support", // The global chat channel. - Used only for presence in support chat demo.
  activeChannel: "support." + generatedName, // In the support demo this is used to set the active channel that a user should use for messages. The generated name is used to create a support channel for that user.
  pubnub: new PubNub({
    publishKey: keyConfiguration.publishKey, // See config/pubnub-keys.json.
    subscribeKey: keyConfiguration.subscribeKey, // See config/pubnub-keys.json.
    uuid: generatedName, // Use the UUID for identification on PubNub.
  }),
};

function generateName(): string {
  // TODO: Will be used me request (id of the current user is required)
  return startCase("develop");
}

type Message = {
  message: string;
  senderName: string;
  senderAvatar?: string;
};

//This is the default settings for your chat app.
export interface LiveChatState {
  presence: boolean;
  presenceLastUpdated: number;
  history: boolean;
  historyMax: number;
  maxMessagesInList: number;
  selfName: string;
  message: string;
  messages: Message[];
  activeUsers: string[];
  channel: string;
  activeChannel: string;
  pubnub: PubNub;
}

type LiveChatAction =
  | {
      type: "ADD_MESSAGE";
      payload: Message;
    }
  | {
      type: "ADD_HISTORY";
      payload: string[];
    }
  | {
      type: "ADD_ACTIVEUSERS";
      payload: string[];
    }
  | {
      type: "SEND_MESSAGE";
      payload: string;
    };

interface LiveChatContextProps {
  state: LiveChatState;
  dispatch: Dispatch<LiveChatAction>;
}

export const LiveChatContext = createContext<LiveChatContextProps>(
  {} as LiveChatContextProps
);

// The functions below are accessible through passing parameters to a dispatch function always accessible in our components.
export const liveChatReducer = (
  state: LiveChatState,
  action: LiveChatAction
): LiveChatState => {
  switch (action.type) {
    // ADD_MESSAGE adds an incoming message to our internal MessageList buffer.
    case "ADD_MESSAGE": {
      //If the messagelist is over our cap we discard the oldest message in the list.
      if (state.messages.length > state.maxMessagesInList) {
        state.messages.shift();
      }
      const addMessage: LiveChatState = {
        ...state,
        messages: [...state.messages, { ...action.payload }],
      };

      return addMessage;
    }
    // ADD_ACTIVEUSERS replaces array of users in our internal activeUsers buffer.
    case "ADD_ACTIVEUSERS": {
      const activeUsersList: LiveChatState = {
        ...state,
        activeUsers: [...action.payload],
      };
      return activeUsersList;
    }
    // Publishes a message to chat channel.
    case "SEND_MESSAGE": {
      state.pubnub.publish({
        channel: state.activeChannel, // Publish support client message to active channel.
        message: {
          message: DOMPurify.sanitize(action.payload),
          senderName: state.selfName,
        },
      });
      return { ...state };
    }
    default: {
      return state;
    }
  }
};

export const LiveChatProvider = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const [state, dispatch] = useReducer(liveChatReducer, liveChatState);

  useEffect(() => {
    try {
      //This where PubNub receives messages subscribed by the channel.
      state.pubnub.addListener({
        message: (messageEvent) => {
          if (messageEvent.channel === state.activeChannel) {
            // Only add messages sent to activeChannel
            dispatch({
              type: "ADD_MESSAGE",
              payload: {
                ...messageEvent.message,
                message: DOMPurify.sanitize(messageEvent.message.message),
              },
            });
          }
        },
      });

      // Subscribe on the default channel.
      state.pubnub.subscribe({
        channels: [state.channel, state.activeChannel], // Subscribe to both the global and active channel.
        withPresence: state.presence,
      });
    } catch (e) {
      console.log(`Subscribe error ${e}`);
    }
  }, [liveChatState]);

  return (
    <LiveChatContext.Provider value={{ state, dispatch }}>
      {children}
    </LiveChatContext.Provider>
  );
};

const useLiveChatContext = (): LiveChatContextProps => {
  return useContext(LiveChatContext);
};

export default useLiveChatContext;
