import { AxiosResponse } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { Case } from "types";
import { axios } from "utils";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction } from "react";

export const useSendNewCase = (
  setSelectedCase: Dispatch<SetStateAction<Case>>
): UseMutationResult<
  { case_name: string; case_description: string; case_id: string } | undefined,
  unknown,
  Case,
  unknown
> => {
  const queryClient = useQueryClient();
  const newCase = useMutation({
    mutationFn: async ({ case_name, case_description }: Case) => {
      const response: AxiosResponse<{
        case_name: string;
        case_description: string;
        case_id: string;
      }> = await toast.promise(
        axios({
          method: "post",
          url: process.env.REACT_APP_API + `/core/case`,
          data: {
            case_name,
            case_description,
          },
        }),
        {
          pending: "New Case uploading...",
          success: "Case created successfully 👌",
          error: "Case upload rejected 🤯",
        }
      );

      return response.data;
    },
    onSuccess: (response: any) => {
      console.log("success");
      setSelectedCase({
        case_id: response?.case_id,
        case_name: response?.case_name,
        case_description: response?.case_description,
      });
      queryClient.invalidateQueries("cases");
    },
    onError: (response) => {
      console.log(response);
    },
  });

  return newCase;
};

export default useSendNewCase;
