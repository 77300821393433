import { useQuery, UseQueryResult } from 'react-query';
import { IDataImport } from 'types';
import { axios, getDecodenToken } from 'utils';

const useGetDataImports = ({
  case_id,
}: {
  case_id?: string;
}): UseQueryResult<IDataImport> => {
  const role = getDecodenToken();
  const dataImportDetails = useQuery(
    ['dataImportDetails', case_id],
    async () => {
      if (case_id !== '') {
        try {
          const { data } = await axios({
            url:
              process.env.REACT_APP_API +
              `/core/case/${case_id?.toString()}/data_import`,
          });
          return data;
        } catch (e) {
          return [];
        }
      } else return [];
    },
    {
      enabled: case_id !== '' && role['data-import-enabled'],
      refetchOnWindowFocus: true,
    }
  );
  return dataImportDetails;
};

export default useGetDataImports;
