import { useQuery, UseQueryResult } from "react-query";

import { RunInfoById, RunType } from "types";
import { axios } from "utils";

const useGetRunInfoOfRunId = ({
  case_id,
  solution_id,
  run_type,
  run_id,
}: {
  case_id?: string;
  solution_id?: string;
  run_type?: RunType;
  run_id?: string | null;
}): UseQueryResult<RunInfoById> => {
  const runDetails = useQuery(
    ["runDetails", run_id],
    async () => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API +
          `/core/case/${case_id?.toString()}/solution/${solution_id?.toString()}/run/${run_type}/${run_id}`,
      });
      return data;
    },
    { enabled: !!run_type && !!run_id, refetchInterval: 2000 }
  );
  return runDetails;
};

export default useGetRunInfoOfRunId;
