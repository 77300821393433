import { Components } from "@mui/material";

import baseTheme from "theme/baseTheme";

const MuiTooltip: Components["MuiTooltip"] = {
	styleOverrides: {
		tooltip: {
			backgroundColor: baseTheme.palette.secondary.main,
			color: baseTheme.palette.common.white,
			fontWeight: baseTheme.typography.fontWeightBold,
			maxWidth: 200,
			padding: 10,
		},
	},
};

export default MuiTooltip;
