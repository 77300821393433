import { Components } from "@mui/material";

const MuiIconButton: Components["MuiIconButton"] = {
	defaultProps: {
		color: "inherit",
		size: "small",
	},
};

export default MuiIconButton;
