import { Box, TextField, FormControl, Grid, Grow, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HeaderDiv from 'components/HeaderDiv';
import { useSendTrigger } from 'hooks';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataImportActions } from 'redux/slices/dataImportSlice';
import { RootState } from 'redux/store';
import { Case, RunInfoById, RunInfo, Solution } from 'types';
import CustomizedRadios from './CustomizedRadios';
import ForecastHorizonValidation from './Validations/ForecastHorizonValidation';
import { ReactComponent as RunLoading } from 'assets/Loading/RunLoading.svg';
import { ReactComponent as PredictionLoading } from 'assets/Loading/PredictionLoading.svg';

import { format } from 'date-fns';
import { toNumber } from 'lodash';
import { useNavigate } from 'react-router-dom';

const StartPrediction = ({
  setActiveStep,
  trainingInfo,
  setNewPredictionFinished,
  useMostRecentPrediction,
  setUseMostRecentPrediction,
  predictionRunsWithStatus,
  caseInfo,
  solutionInfo,
  loading,
  newPredictionFinished,
  setLoading,
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  trainingInfo: RunInfoById | undefined;
  newPredictionFinished: boolean | null;
  setNewPredictionFinished: Dispatch<SetStateAction<boolean | null>>;
  useMostRecentPrediction: boolean;
  predictionRunsWithStatus: RunInfo[] | undefined;
  setUseMostRecentPrediction: Dispatch<SetStateAction<boolean>>;
  caseInfo: Case;
  solutionInfo: Solution;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRadio, setSelectedRadio] = useState('newPrediction');

  const [radioButtonLabel2, setRadioButtonLabel2] = useState<string | null>(
    null
  );

  const { mutateAsync: sendTrigger } = useSendTrigger('prediction', setLoading);
  const { runConfig } = useSelector((state: RootState) => state.dataImport);

  const { training_run_id } = useSelector(
    (state: RootState) => state.dataImport
  );

  useEffect(() => {
    if (
      predictionRunsWithStatus &&
      predictionRunsWithStatus.length > 0 &&
      predictionRunsWithStatus[0].train_run_id == training_run_id
    ) {
      setRadioButtonLabel2(
        `Use the Most Recent Prediction ( Prediction Date : ${format(
          new Date(predictionRunsWithStatus[0].created_at),
          'dd-MM-yyyy HH:mm:ss'
        )})`
      );
    } else setRadioButtonLabel2(null);
  }, [predictionRunsWithStatus]);

  const handleTriggers = async (runType: string) => {
    setLoading(true);
    setNewPredictionFinished(false);
    setUseMostRecentPrediction(false);
    await sendTrigger({
      case_id: caseInfo.case_id,
      solution_id: solutionInfo.solution_id,
      trainingInfo: trainingInfo,
      triggerType: runType,
      runConfig,
    });
  };

  const onChange = (value: number) => {
    dispatch(dataImportActions.setForecastHorizon(value));
  };

  const handleInspect = () => {
    navigate(
      `/case/${(caseInfo as Case).case_id}/solution/${
        (solutionInfo as Solution).solution_id
      }`
    );
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grow in={true} timeout={800}>
      <Grid container>
        <CustomizedRadios
          pipelineStarted={loading}
          label1={'Start New Prediction'}
          label2={radioButtonLabel2}
          runType={'prediction'}
          values={['newPrediction', 'mostRecentPrediction']}
          value={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          setState={setUseMostRecentPrediction}
        />
        <Grid item xs={12} md={12} lg={8} xl={8}>
          {!useMostRecentPrediction && (
            <Grow in={true} timeout={800}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl sx={{ mt: 2 }}>
                  <HeaderDiv title={'Start Prediction'} />
                  <TextField
                    label='forecast_horizon'
                    type='number'
                    margin='normal'
                    required
                    helperText={
                      ForecastHorizonValidation(runConfig.forecast_horizon)
                        ? 'This number tells Predy how far into the future to predict your data at the specified forecast frequency'
                        : 'forecast_horizon must be greater than 0'
                    }
                    error={
                      !ForecastHorizonValidation(runConfig.forecast_horizon)
                    }
                    onChange={(e) => onChange(toNumber(e.target.value))}
                  />
                </FormControl>

                <FormControl>
                  <LoadingButton
                    loadingIndicator={<PredictionLoading />}
                    loading={loading}
                    onClick={() => handleTriggers('prediction')}
                    sx={{ width: '100%', backgroundColor: '#e74948' }}
                    disabled={
                      !ForecastHorizonValidation(runConfig.forecast_horizon)
                    }
                    variant={'contained'}
                    color={'error'}
                    size={'large'}
                  >
                    Start Prediction
                  </LoadingButton>
                </FormControl>
              </Box>
            </Grow>
          )}
          <LoadingButton
            loadingIndicator={<RunLoading />}
            sx={{ mb: 2, mt: 7, minWidth: '100px' }}
            variant={'contained'}
            color={'success'}
            disabled={!(useMostRecentPrediction || newPredictionFinished)}
            onClick={handleInspect}
          >
            INSPECT
          </LoadingButton>
          <Button
            color='inherit'
            onClick={handleBack}
            sx={{ ml: 5, mb: 2, mt: 7 }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Grow>
  );
};
export default StartPrediction;
