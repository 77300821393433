import { ReactComponent as RunLoading } from "assets/Loading/RunLoading.svg";
import { Button, Grid, Grow, Tooltip, Typography } from "@mui/material";
import Form from "./FileColumnForm";
import Dropzone from "components/Dropzone";
import { LoadingButton } from "@mui/lab";
import { useHandleSendFiles } from "../UploadHooks/useSendFiles";
import { Case } from "types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FileColumnValidation } from "./Validations";
import LinearProgressWithLabel from "components/LinearProgressWithLabel";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
const UploadCaseData = ({
  setActiveStep,
  isSideInfo,
  caseInfo,
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  caseInfo: Case;
  isSideInfo: boolean;
}): JSX.Element => {
  const { actualFileDataMetadata } = useSelector(
    (state: RootState) => state.dataImport
  );

  const actualDataColumns = useSelector(
    (state: RootState) => state.dataImport.actualDataFileColumns.column
  );
  const { actualFile } = useSelector((state: RootState) => state.dataImport);

  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [fileUploadError, setFileUploadError] = useState(false);

  const { sendFiles } = useHandleSendFiles(
    caseInfo,
    setFileUploadError,
    setFileUploadLoading,
    setFileUploadProgress,
    fileUploadProgress
  );

  useEffect(() => {
    if (fileUploadProgress === 100 && !fileUploadLoading && !fileUploadError) {
      setActiveStep(2);
      setFileUploadProgress(0);
    } else if (fileUploadError) {
      setFileUploadProgress(0);
      setFileUploadError(false);
    }
  }, [fileUploadProgress, fileUploadLoading]);

  const handleContinue = async () => {
    setFileUploadLoading(true);
    await sendFiles();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Grow in={true} timeout={800}>
        <Grid item xs={12}>
          <Typography variant="h5" ml={1}>
            Upload File and Select the Columns
          </Typography>
          <Dropzone accept="text/csv" isSideInfo={isSideInfo} />
          <Form isSideInfo={isSideInfo} />
        </Grid>
      </Grow>
      <Grow in={true} timeout={800}>
        <Grid>
          <Tooltip title="Start Formatter..." arrow>
            <LoadingButton
              loading={fileUploadLoading}
              loadingIndicator={<RunLoading />}
              sx={{ mb: 2, mt: 7 }}
              variant={"contained"}
              color={"error"}
              disabled={
                fileUploadLoading ||
                FileColumnValidation(
                  actualFileDataMetadata,
                  actualDataColumns,
                  actualFile
                )
              }
              onClick={handleContinue}
            >
              {"Continue"}
            </LoadingButton>
          </Tooltip>
          <Button
            color="inherit"
            onClick={handleBack}
            sx={{ ml: 5, mb: 2, mt: 7 }}
          >
            Back
          </Button>
          {fileUploadLoading && fileUploadProgress !== 0 && (
            <LinearProgressWithLabel value={fileUploadProgress} />
          )}
        </Grid>
      </Grow>
    </>
  );
};
export default UploadCaseData;
