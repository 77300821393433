import { AxiosResponse } from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { useMutation, UseMutationResult } from 'react-query'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { stepperActions } from 'redux/slices'
import { dataImportActions } from 'redux/slices/dataImportSlice'
import { IActualDataMetadata } from 'types/dataImport.type'
import { ActualFileDataColumn } from 'types/dataImport.type'
import { axios } from 'utils'

export type CompletedChunkDescription = {
  session_id?: string
  case_id: string
}

interface IChunkRequest extends ActualFileDataColumn {
  date_format: string
  time_frequency: string
  hierarchy_column: string
}

export const useSendChunkCompleted = (
  setProgress: Dispatch<SetStateAction<number>>,
  setCounter: Dispatch<SetStateAction<number>>,
  actualFileDataMetadata: IActualDataMetadata,
  actualDataColumns: ActualFileDataColumn,
  setFileUploadLoading: Dispatch<SetStateAction<boolean>>,
  setFileUploadError: Dispatch<SetStateAction<boolean>>
): UseMutationResult<
  AxiosResponse,
  unknown,
  CompletedChunkDescription,
  unknown
> => {
  const dispatch = useDispatch()
  const sendChunkCompleted = useMutation(
    async ({ session_id, case_id }: CompletedChunkDescription) => {
      const fileColumnData: IChunkRequest = {
        date_format: '',
        time_frequency: '',
        hierarchy_column: '',
      }
      fileColumnData['target_column'] =
        actualDataColumns.target_column as string
      fileColumnData['hierarchy_column'] =
        actualDataColumns.hierarchy_column as string
      fileColumnData['date_column'] = actualDataColumns.date_column as string

      if (actualDataColumns.feature_columns)
        fileColumnData['feature_columns'] = actualDataColumns.feature_columns

      fileColumnData['date_format'] = actualFileDataMetadata.date_format
        ?.trimStart()
        .trimEnd() as string

      fileColumnData['time_frequency'] =
        actualFileDataMetadata.time_frequency as string
      return toast.promise(
        axios({
          method: 'post',
          data: fileColumnData,
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id.toString()}/data_import/multipart/commit?session_id=${session_id}`,
        }),
        {
          pending: 'File uploading...',
          success: 'File uploaded successfully 👌',
          error: {
            render({ data }) {
              return (
                <>
                  {'File upload rejected. \t ' +
                    data.response.data.Error +
                    '🤯'}
                </>
              )
            }, //},
          },
        }
      )
    },
    {
      onSuccess: (response) => {
        setFileUploadLoading(false)
        dispatch(
          stepperActions.setHierarchyDropdown(
            response.data.data_metadata.hierarchy_ids
          )
        )
        dispatch(
          dataImportActions.setRunStartEndDate({
            start_date: response.data.data_metadata.data_start_date,
            end_date: response.data.data_metadata.data_end_date,
          })
        )
        dispatch(
          stepperActions.setTrainingDates({
            default_start_date: new Date(
              response.data.data_metadata.data_start_date as Date
            ),
            default_end_date: new Date(
              response.data.data_metadata.data_end_date as Date
            ),
          })
        )
      },
      onError: (response) => {
        setProgress(0)
        setCounter(1)
        setFileUploadError(true)
        console.log(response)
        setFileUploadLoading(false)
      },
    }
  )

  return sendChunkCompleted
}

export default useSendChunkCompleted
