import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { axios } from "utils";

export type ChunkDescription = {
  beginningOfTheChunk: number;
  endOfTheChunk: number;
  setBeginingOfTheChunk: Dispatch<SetStateAction<number>>;
  setEndOfTheChunk: Dispatch<SetStateAction<number>>;
  chunkSize: number;
  chunk: Blob;
  session_id?: string;
  is_side_info: boolean;
  case_id: string;
  file: File;
};

export const useSendChunk = (
  setSessionId: Dispatch<SetStateAction<string | undefined>>,
  setProgress: Dispatch<SetStateAction<number>>,
  counter: number,
  setCounter: Dispatch<SetStateAction<number>>,
  chunkCount: number,
  setFileUploadLoading: Dispatch<SetStateAction<boolean>>,
  setFileUploadError: Dispatch<SetStateAction<boolean>>
): UseMutationResult<AxiosResponse, unknown, ChunkDescription, unknown> => {
  const sendChunk = useMutation(
    async ({
      beginningOfTheChunk,
      endOfTheChunk,
      setBeginingOfTheChunk,
      setEndOfTheChunk,
      chunkSize,
      chunk,
      session_id,
      case_id,
      file,
    }: ChunkDescription) => {
      const formData = new FormData();
      formData.append(`file`, chunk);

      const headers = {
        "Content-Range": `${beginningOfTheChunk}-${endOfTheChunk}/${file.size}`,
      };

      const { data } = await axios({
        method: "post",
        data: formData,
        headers: headers,
        url:
          process.env.REACT_APP_API +
          `/core/case/${case_id.toString()}/data_import/multipart${
            session_id ? "?session_id=" + session_id : ""
          }`,
      });

      setBeginingOfTheChunk(endOfTheChunk);
      if (file.size < endOfTheChunk + chunkSize) {
        setEndOfTheChunk(file.size);
      } else setEndOfTheChunk(endOfTheChunk + chunkSize);

      return data;
    },
    {
      onSuccess: (response) => {
        setCounter(counter + 1);
        setSessionId(response.session_id);
        setProgress((counter / chunkCount) * 100);
      },
      onError: (response) => {
        setFileUploadError(true);
        setProgress(0);
        setCounter(1);
        console.log(response);
        setFileUploadLoading(false);
      },
    }
  );
  return sendChunk;
};

export default useSendChunk;
