import { useEffect, useState } from 'react';
import {
  TextField,
  MenuItem,
  FormControl,
  Chip,
  Autocomplete,
  Grid,
  FormHelperText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { dataImportActions } from 'redux/slices/dataImportSlice';

import { MdEdit, MdEditOff } from 'react-icons/md';

export default function Form({ isSideInfo }: { isSideInfo: boolean }) {
  const [options, setOptions] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [dateFormatDisabled, setDateFormatdisabled] = useState(true);

  const fileNameOptions =
    useSelector(
      (state: RootState) =>
        state.stepper.sideInfoDataFileDropdownInfos.file_names
    ) || [];

  //ColumnOptions get for from dropdowns.
  const actualColumnOptions =
    useSelector(
      (state: RootState) => state.stepper.actualDataFileDropdownInfos.options
    ) || [];
  const { actualFileDataMetadata } = useSelector(
    (state: RootState) => state.dataImport
  );
  const actualColumns =
    useSelector(
      (state: RootState) => state.dataImport.actualDataFileColumns.column
    ) || [];

  const dispatch = useDispatch();

  const onChangeColumns = (column: any) => {
    dispatch(dataImportActions.setActualColumnsState(column));
  };

  //**  seçilen option bir daha seçilemez.*/
  useEffect(() => {
    setOptions(
      actualColumnOptions.filter((e) => {
        return (
          !Object.values(actualColumns).includes(e) &&
          !Object.values(actualColumns.feature_columns ?? []).includes(e)
        );
      })
    );
  }, [actualColumns]);

  useEffect(() => {
    setOptions(actualColumnOptions);
    dispatch(dataImportActions.removeDataFileStates());
  }, [actualColumnOptions]);

  const menuItems =
    fileNameOptions.length > 0 ? (
      fileNameOptions?.map((item: string, i) => (
        <MenuItem key={i} value={item}>
          {item}
        </MenuItem>
      ))
    ) : (
      <span> No Uploaded File </span>
    );

  const onChangeTimeFrequency = (value: any) => {
    dispatch(
      dataImportActions.setActualFileDataMetadata({
        time_frequency: value.time_frequency,
        date_format:
          value.time_frequency == 'D' ? '%m/%d/%Y' : '%Y-%m-%d %H:%M:%S',
      })
    );
  };
  const onChangeDateFormat = (value: any) => {
    dispatch(dataImportActions.setActualFileDataMetadata(value));
  };
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
      {isSideInfo && (
        <TextField
          sx={{ width: '100px' }}
          select
          label='file_name'
          helperText='Select File'
          variant='standard'
          onChange={(e) => setFileName(e.target.value)}
          value={fileName}
        >
          {menuItems}
        </TextField>
      )}
      <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
        <Autocomplete
          id='size-small-filled-multi'
          sx={{ width: '150px', mr: 4, ml: 1 }}
          options={options}
          isOptionEqualToValue={() => true}
          onChange={(_e, value) => onChangeColumns({ target_column: value })}
          value={actualColumns.target_column}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option}
                size='small'
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant='standard' label='Target Column*' />
          )}
        />

        <Autocomplete
          id='size-small-filled-multi'
          sx={{ width: '150px', mr: 4 }}
          options={options}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={() => true}
          onChange={(_e, value) => onChangeColumns({ date_column: value })}
          value={actualColumns.date_column}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option}
                size='small'
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant='standard' label='Date Column*' />
          )}
        />

        <Autocomplete
          id='size-small-filled-multi'
          sx={{ width: '150px', mr: 4 }}
          options={options}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={() => true}
          onChange={(_e, value) => onChangeColumns({ hierarchy_column: value })}
          value={actualColumns.hierarchy_column}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option}
                size='small'
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              label='Hierarchy Column*'
            />
          )}
        />

        <Autocomplete
          multiple
          disableCloseOnSelect
          id='size-small-filled-multi'
          sx={{ width: '150px' }}
          options={options}
          getOptionLabel={(option) => option}
          value={actualColumns.feature_columns}
          onChange={(_e, value) => onChangeColumns({ feature_columns: value })}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option}
                size='small'
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant='standard' label='Feature Columns' />
          )}
        />
      </FormControl>
      <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          variant='standard'
          sx={{ width: '150px', mr: 4, mt: 1, ml: 1 }}
          label='Time Frequency*'
          type='text'
          margin='normal'
          select
          value={actualFileDataMetadata.time_frequency}
          onChange={(e) =>
            onChangeTimeFrequency({ time_frequency: e.target.value })
          }
          helperText={'Forecast frequency (i.e. daily or hourly)'}
        >
          <MenuItem key={1} value='D'>
            Daily
          </MenuItem>
          <MenuItem key={2} value='H'>
            Hourly
          </MenuItem>
        </TextField>
        <FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            InputProps={{
              endAdornment: dateFormatDisabled ? (
                <MdEditOff
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDateFormatdisabled(false)}
                />
              ) : (
                <MdEdit
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDateFormatdisabled(true)}
                />
              ),
            }}
            variant='standard'
            sx={{ width: '150px', mr: 4, mt: 1, ml: 1 }}
            label='Date Format*'
            type='text'
            disabled={dateFormatDisabled}
            onChange={(e) =>
              onChangeDateFormat({ date_format: e.target.value })
            }
            value={actualFileDataMetadata.date_format}
            margin='normal'
          />

          <FormHelperText>
            <span style={{ width: '100vh' }}>
              <li>Example:</li>
              <span
                style={{
                  paddingLeft: '10px',
                  whiteSpace: 'pre-line',
                  fontWeight: 'bold',
                }}
              >
                -%Y-%m-%d %H:%M:%S
              </span>
              <span
                style={{
                  paddingLeft: '10px',
                }}
              >
                for 1995-01-25 01:15:46{' '}
              </span>
              <br />
              <span
                style={{
                  paddingLeft: '10px',
                  whiteSpace: 'pre-line',
                  fontWeight: 'bold',
                }}
              >
                -%m/%d/%Y
              </span>
              <span
                style={{
                  paddingLeft: '10px',
                }}
              >
                for 01/25/1995
              </span>
              <li> Case and Space Sensitive</li>
              <li>
                For further information see
                <a
                  style={{ paddingLeft: 10 }}
                  href='https://strftime.org/'
                  target='_blank'
                  rel='noreferrer'
                >
                  https://strftime.org/
                </a>
              </li>
            </span>
          </FormHelperText>
        </FormControl>
      </FormControl>
    </Grid>
  );
}
