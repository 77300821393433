import { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Box,
  Stack,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import KeyIcon from '@mui/icons-material/Key';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Graph from 'assets/Graph.svg';
import Icon from 'assets/Icon.svg';
import Line from 'assets/Line.svg';
import Loading from 'assets/Loading';
import { useGetUserEntries, useSignin } from 'hooks';

const Signin = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: signin,
    status: signInStatus,
    isError: isErrorSignin,
    isLoading: isLoadingSignin,
  } = useSignin();

  const onSubmit = async (data: any) => signin(data);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(() => {
    setOpenSnackbar(true);
  }, [isErrorSignin]);

  return (
    <>
      <Stack direction='row'>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='50vw'
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            src='/Intro.mp4'
            width='100%'
          />
        </Box>
        <Box
          width='50vw'
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            backgroundImage: ` url(${Line}), url(${Icon}), url(${Graph})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom left, center, right top',
            backgroundSize: '25%, 60%, 25%',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              justifyContent='center'
              alignItems='center'
              spacing={2}
              height='100vh'
            >
              <Typography
                color='white'
                fontWeight='bold'
                variant='h4'
                gutterBottom
              >
                Sign In
              </Typography>
              <TextField
                {...register('username', { required: 'Required field' })}
                error={Boolean(errors.username)}
                helperText={errors?.username?.message}
                label='Username'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                {...register('password', { required: 'Required field' })}
                error={Boolean(errors.password)}
                helperText={errors?.password?.message}
                label='Password'
                type='password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {isLoadingSignin ? (
                <Loading />
              ) : (
                <Button type='submit'>Sign in</Button>
              )}
            </Stack>
          </form>
        </Box>
      </Stack>
      {isErrorSignin && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert severity='error' onClose={() => setOpenSnackbar(false)}>
            Please make sure to enter correct credentials and try again.
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Signin;
