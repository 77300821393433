import { useQuery, UseQueryResult } from 'react-query';
import { IEnrtyDetails } from 'types/main.type';

import { axios } from 'utils';

const useGetDetailsEntries = ({
  entry_id,
}: {
  entry_id: number | null;
}): UseQueryResult<IEnrtyDetails> => {
  const filters = useQuery(
    ['filters', entry_id],
    async () => {
      const { data } = await axios({
        url:
          process.env.REACT_APP_API_EXTERNAL +
          `/external_api/${entry_id}/details`,
      });
      return data;
    },
    {
      enabled: !!entry_id,
    }
  );
  return filters;
};

export default useGetDetailsEntries;
