import { useGetUserEntries } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from 'redux/slices';
import { RootState } from 'redux/store';
import { SelectPicker } from 'rsuite';
import { IEnrty } from 'types/main.type';

export default function EntrySelection() {
  const { data } = useGetUserEntries();
  const dispatch = useDispatch();

  const entryId = useSelector(
    (state: RootState) => state.main.configState.entryId
  );
  const selectionData = useMemo(
    () =>
      data?.map((item: IEnrty) => ({
        label: item.forecast_table_label,
        value: item.id,
      })),
    [data]
  );

  useEffect(() => {
    if (data)
      dispatch(
        mainActions.setConfigData({
          entryId: data[0]?.id,
        })
      );
  }, [data]);

  const onChange = (value: number | null) => {
    if (value)
      dispatch(
        mainActions.setConfigData({
          entryId: value,
        })
      );
  };

  return (
    <>
      <SelectPicker
        value={entryId}
        cleanable={false}
        onChange={(e: number | null) => onChange(e)}
        label='Forecast Table'
        data={selectionData}
        style={{ width: 300 }}
      />
    </>
  );
}
