import { KeycloakResponse } from 'types';
import jwt_decode from 'jwt-decode';

const getDecodenToken = (): any => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken: any = jwt_decode(
        (JSON.parse(token) as KeycloakResponse).access_token
      );
      if (!decodedToken['groups']?.includes('ui-user'))
        decodedToken['data-import-enabled'] =
          process.env.REACT_APP_DATA_IMPORT_ENABLED?.toLowerCase() === 'true'
            ? true
            : false;
      if (decodedToken['groups']?.includes('ui-user'))
        decodedToken['data-import-enabled'] = true;

      //  "groups": [
      //   "api-user",
      //   "ui-user"
      // ],

      return decodedToken;
    }
  } catch (error) {
    console.log('checkRole : ', error);
  }
  localStorage.removeItem('token');
  return null;
};

export default getDecodenToken;
