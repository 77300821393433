import { useQuery, UseQueryResult } from "react-query";

import { Solution } from "types";
import { axios } from "utils";

const useGetAllSolutions = (
  case_id: string | undefined
): UseQueryResult<Solution[]> => {
  const data_sources = useQuery(
    "solutions",
    async () => {
      if (case_id !== "") {
        const { data } = await axios({
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id?.toString()}/solution`,
        }).catch(() => {
          return { data: [] };
        });
        return data;
      } else return [];
    },

    {
      onError: () => {
        return [];
      },
    }
  );
  return data_sources;
};

export default useGetAllSolutions;
