//@ts-nocheck
import { useEffect } from 'react'

import { Box, Button, ListItem, Tooltip, useTheme } from '@mui/material'
import { MdInfo } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import Filters from 'views/Main/ChartConfiguration/Filters'
import Layers from 'views/Main/ChartConfiguration/Layers'
import { RootState } from 'redux/store'
import { getDecodenToken, setRunId } from 'utils'
import { useParams } from 'react-router'
import { mainActions } from 'redux/slices'
import { isEmpty } from 'lodash'
import useGetPredictionMetadata from 'hooks/endpoints/useGetPredictionMetadata'
import EntrySelection from './EntrySelection'
import { EntryLayers } from './EntryLayers'
import { useGetDetailsEntries } from 'hooks'
import { EntryDatePicker } from './EntryDatePicker'

const ChartConfiguration = ({ refetch }: { refetch: any }): JSX.Element => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const role = getDecodenToken()

  const { case_id, solution_id } = useParams()

  const entryId = useSelector((state: RootState) => {
    return state.main.configState.entryId
  })

  // const [error, setError] = useState(false); // error boundaries
  // function Bomb() {
  //   throw new Error("💥 CABOOM 💥");
  // }

  useEffect(() => {
    setRunId(case_id, solution_id, dispatch)
  }, [case_id, solution_id])

  const selectedDataGroup = useSelector(
    (state: RootState) => state.main.chartConfigurationState.dataGroups
  )

  const { data: predictionRunDetails } = useGetPredictionMetadata({
    case_id: case_id,
    solution_id: solution_id,
    dispatch,
  })

  const { data: entryDetails } = useGetDetailsEntries({ entry_id: entryId })

  useEffect(() => {
    if (predictionRunDetails) {
      dispatch(
        mainActions.setConfigData({
          runMetadata: {
            train_start_date:
              predictionRunDetails.metadata.training_data_range.start_date,
            train_end_date:
              predictionRunDetails.metadata.training_data_range.end_date,
            prediction_start_date:
              predictionRunDetails.metadata.prediction_data_range.start_date,
            prediction_end_date:
              predictionRunDetails.metadata.prediction_data_range.end_date,
            description: predictionRunDetails.metadata.description,
          },
        })
      )
    }
  }, [predictionRunDetails])

  const { layerData: layers } = useSelector(
    (state: RootState) => state.main.layerState
  )

  const onClickApply = () => {
    refetch()
  }

  return (
    <Box display='flex' ml={-2} flexDirection={'row'}>
      {!role['data-import-enabled'] && (
        <ListItem>
          <EntrySelection />
        </ListItem>
      )}
      {role['data-import-enabled'] ? (
        <>
          <Layers layers={layers?.length !== 0 ? layers : undefined} />
          <Filters />
        </>
      ) : (
        <>
          <EntryLayers entryDetails={entryDetails} />
          <EntryDatePicker entryDetails={entryDetails} />
        </>
      )}

      {/* {error ? <Bomb /> : ""} */}

      <ListItem>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <Tooltip title='Please select at least one layer to apply the configuration.'>
            <Box
              sx={{
                marginRight: 0.5,
                color: theme.palette.blue.main,
              }}
            >
              <MdInfo />
            </Box>
          </Tooltip>

          <Button disabled={isEmpty(selectedDataGroup)} onClick={onClickApply}>
            APPLY
          </Button>
        </Box>
      </ListItem>
    </Box>
  )
}

export default ChartConfiguration
