import { useEffect } from 'react';
import { Alert, Snackbar, styled, Box, useTheme } from '@mui/material';
import { useFetchChartData } from 'hooks';
import { ToolbarMixin } from 'mixins';
import Chart from 'views/Main/Chart';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { mainActions } from 'redux/slices';
import ChartConfiguration from './ChartConfiguration';
import { useQueryClient, QueryCache } from 'react-query';

interface ContentProps {
  openDrawer?: boolean;
  drawerWidth?: number;
}

const Content = styled('main', {
  shouldForwardProp: (prop) => prop !== 'openDrawer' && prop !== 'drawerWidth',
})<ContentProps>(({ theme, drawerWidth, openDrawer }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(openDrawer && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  overflow: 'hidden',
}));

const Main = (): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryCache = new QueryCache({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (data) => {
      console.log(data);
    },
  });
  useEffect(() => {
    return () => {
      queryCache.clear();
      queryClient.removeQueries('predictionMetadata');
      queryClient.removeQueries('finetuningMetadata');
      dispatch(mainActions.clearStates());
    };
  }, []);

  const openDrawer = useSelector(
    (state: RootState) => state.common.commonState.openDrawer
  );

  const { chartConfigurationState } = useSelector(
    (state: RootState) => state.main
  );

  const { refetch: refetchChartData, isError: isErrorChartData } =
    useFetchChartData(chartConfigurationState);

  return (
    <>
      <Content openDrawer={openDrawer}>
        <ToolbarMixin />
        <Box
          border='2px solid'
          borderRadius={theme.shape.borderRadius}
          borderColor='primary.main'
          marginBottom={1}
          padding={1}
          width='100%'
        >
          <ChartConfiguration refetch={refetchChartData} />
          <Chart />
        </Box>
      </Content>

      <Snackbar autoHideDuration={5000} open={isErrorChartData}>
        <Alert sx={{ width: '100%' }} severity='error'>
          Unexpected server error
        </Alert>
      </Snackbar>
    </>
  );
};

export default Main;
