import { useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Collapse,
} from '@mui/material';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { cloneDeep, isEmpty, toNumber } from 'lodash';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Layer } from 'types';
import useGetFinetuningMetadata from 'hooks/endpoints/useGetFinetuningMetadata';
interface Column {
  id:
    | 'model_name'
    | 'start_date'
    | 'end_date'
    | 'train_score'
    | 'validation_score';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
const columns: Column[] = [
  { id: 'model_name', label: 'Model Name', minWidth: 170 },
  { id: 'start_date', label: 'Training Start Date', minWidth: 100 },
  {
    id: 'end_date',
    label: 'Training End Date',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'train_score',
    label: 'Train Score',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'validation_score',
    label: 'Validation Score',
    minWidth: 170,
    format: (value: number) => value.toFixed(2),
  },
];

const ChartHeader = (): JSX.Element => {
  const transposeTableCell = (finalObject: any, data: any) => {
    data?.forEach((element: any) => {
      element.model_explanations.forEach((e: any) => {
        finalObject[e.model_name] = [];
      });
    });
    data?.forEach((element: any) => {
      element.model_explanations.forEach((e: any) => {
        finalObject[e.model_name].push(e);
      });
    });

    let newObject = cloneDeep(finalObject);
    Object.keys(finalObject).forEach((key) => {
      finalObject[key].forEach((element: any, i: number) => {
        Object.keys(element).forEach((key2) => {
          if (key2 === 'start_date' || key2 === 'end_date') {
            newObject[key][i][key2] = format(
              new Date(element[key2]),
              'dd-MM-yyyy HH:mm:ss' //TODO : saatler gelicek
            );
          }
          if (key2 === 'train_score' || key2 === 'validation_score') {
            newObject[key][i][key2] = toNumber(element[key2]).toFixed(4);
          }
        });
      });
    });
    return newObject;
  };

  const [expanded, setExpanded] = useState(false);
  const { case_id, solution_id } = useParams();

  const { data: trainRunDetails } = useGetFinetuningMetadata({
    case_id: case_id,
    solution_id: solution_id,
  });

  let tableData: any = {};
  tableData = useMemo(
    () => transposeTableCell({}, trainRunDetails?.tree.run_tree),
    [trainRunDetails]
  );

  const [runTree, setRunTree] = useState<Layer[] | undefined>([]);

  const [open, setOpen] = useState<any>({});

  useEffect(() => {
    if (trainRunDetails?.tree.run_tree.length === 1) {
      trainRunDetails?.tree.run_tree.forEach((e: any) => {
        open[e.label] = true;
      });
    } else {
      trainRunDetails?.tree.run_tree.forEach((e: any) => {
        open[e.label] = false;
      });
    }
    setOpen({ ...open });
    setRunTree(trainRunDetails?.tree.run_tree);
  }, [trainRunDetails]);

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      square
      variant='outlined'
      sx={{
        borderColor: (theme) => theme.palette.corn.main,
      }}
    >
      <AccordionSummary
        sx={{
          '&:hover:not(.Mui-disabled)': {
            cursor: 'default',
          },
        }}
      >
        <Typography color='corn.main' fontWeight='bold' variant='h6'>
          {'INFORMATIONS '}
        </Typography>
        {expanded ? (
          <IconButton
            sx={{ color: 'corn.main' }}
            onClick={() => setExpanded(!expanded)}
          >
            <MdExpandLess />
          </IconButton>
        ) : (
          <IconButton
            sx={{ color: 'corn.main' }}
            onClick={() => setExpanded(!expanded)}
          >
            <MdExpandMore />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Paper>
          <TableContainer>
            {runTree?.map((layer: any) => (
              <Table stickyHeader aria-label='sticky table'>
                <TableRow>
                  <TableCell
                    sx={{
                      border: 1,
                      fontWeight: 'bold',
                      backgroundColor: '#121f78e0',
                      color: '#fff',
                    }}
                    align='center'
                    colSpan={columns.length}
                  >
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => {
                        setOpen({
                          ...open,
                          [layer.label]: !open[layer.label],
                        });
                      }}
                    >
                      {open[layer.label] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>

                    {layer.label}
                  </TableCell>
                </TableRow>
                <Collapse in={open[layer.label]} timeout='auto' unmountOnExit>
                  <Table sx={{ width: '100%' }}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: '#fff',
                            fontWeight: 'bold',
                            color: '#fff',
                            backgroundColor: '#121f7896', //index % 2 == 0 ? "#6c7ae0" : "#6c7ae0",
                          }}
                          key={column.id}
                          align={column.align}
                          style={{ top: 57 }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableBody>
                      {!isEmpty(Object.keys(tableData)) &&
                        Object.keys(tableData).map((modelName: any, index) => (
                          <TableRow
                            hover
                            sx={{
                              backgroundColor:
                                index % 2 == 0 ? '#2c42e326' : '#2c42e30f',
                            }}
                          >
                            {tableData[modelName].map((e: any) => {
                              if (e.cluster_name == layer.label)
                                return columns.map((column) => {
                                  const value = e[column.id];
                                  return (
                                    <TableCell
                                      sx={{
                                        border: 0.5,
                                        borderColor: '#fff',
                                      }}
                                      key={column.id}
                                    >
                                      {column.format &&
                                      typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                });
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Collapse>
              </Table>
            ))}
          </TableContainer>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChartHeader;
