import { notificationActions } from "../slices/notificationSlice";
import { FETCH_STATUS, IProcess } from "../../types/notification.type";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
export const notificationInterceptorMiddleware =
  () => (next: (arg0: any) => any) => (action: PayloadAction<IProcess>) => {
    if (action.type === `${notificationActions.setProcessInfo.type}`) {
      const { status, error, message }: IProcess = action.payload;
      switch (status) {
        case FETCH_STATUS.ERROR:
          error &&
            error !== "" &&
            toast.error(error, {
              theme: "colored",
            });
          break;
        case FETCH_STATUS.SUCCESS:
          message &&
            message !== "" &&
            toast.success(message, {
              icon: "🚀",
            });
          break;
        case FETCH_STATUS.INFO:
          message &&
            message !== "" &&
            toast.info(message, {
              theme: "colored",
            });
          break;
        default:
          break;
      }
    }
    return next(action);
  };
