import {
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
} from "react";

import {
  Avatar,
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  TextField,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { MdChatBubbleOutline } from "react-icons/md";

import { RootState } from "redux/store";

import PredySupport from "assets/PredySupport.svg";
import { useLiveChatContext } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/slices";

const LiveChat = (): JSX.Element => {
  const theme = useTheme();

  const dispatchRedux = useDispatch();

  const [openChat, setOpenChat] = useState(false);
  const [chatButtonRef, setChatButtonRef] = useState<HTMLButtonElement>();
  const { state, dispatch } = useLiveChatContext();
  const [message, setMessage] = useState(
    "Something goes wrong. Could you please check for me ? "
  );

  const showPubNub = useSelector(
    (state: RootState) => state.common.commonState.showPubNub
  );

  useEffect(() => {
    setOpenChat(showPubNub);
  }, [showPubNub]);

  const chatButtonCallbackRef = useCallback((buttonNode) => {
    setChatButtonRef(buttonNode);
  }, []);

  const appDispatch = useDispatch();

  const handleClickChat = () => {
    if (openChat === true) {
      appDispatch(commonActions.setShowPubNub(false));
    }
    setOpenChat(!openChat);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event?.key === "Enter") {
      event.preventDefault(); // Prevent default new line
      if (!isEmpty(message)) {
        dispatch({
          type: "SEND_MESSAGE",
          payload: message,
        });
        setMessage("");
      }
    }
  };

  const handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  return (
    <>
      <IconButton
        sx={{ position: "fixed", bottom: 20, right: 20 }}
        ref={chatButtonCallbackRef}
        onClick={handleClickChat}
      >
        <MdChatBubbleOutline />
      </IconButton>
      {openChat && chatButtonRef && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenChat(false);
            dispatchRedux(commonActions.setShowPubNub(false));
          }}
        >
          <Popper
            style={{ zIndex: theme.zIndex.modal }}
            open={openChat}
            anchorEl={chatButtonRef}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Paper
                  sx={{
                    position: "fixed",
                    height: 450,
                    width: 350,
                    right: 20,
                    bottom: 20,
                  }}
                  elevation={10}
                >
                  <List sx={{ maxHeight: 352, overflow: "auto" }}>
                    {state.messages.map((message, index) => {
                      const avatar =
                        message.senderName === "Predy Support"
                          ? PredySupport
                          : "https://ui-avatars.com/api/?name=" +
                            localStorage.getItem("userName") +
                            "uppercase=true";
                      return (
                        <ListItem
                          sx={{
                            "&::after": {
                              content: '""',
                              position: "absolute",
                              borderLeft: (theme) =>
                                `5px solid ${
                                  state.selfName === message.senderName
                                    ? theme.palette.blue.main
                                    : "transparent"
                                }`,
                              borderTop: "5px solid transparent",
                              borderRight: (theme) =>
                                `5px solid ${
                                  state.selfName === message.senderName
                                    ? "transparent"
                                    : theme.palette.corn.main
                                }`,
                              borderBottom: "5px solid transparent",
                              ...(state.selfName === message.senderName
                                ? { right: 62 }
                                : { left: 62 }),
                            },
                          }}
                          key={index}
                        >
                          {state.selfName !== message.senderName && (
                            <ListItemAvatar>
                              <Avatar
                                alt={message.senderName}
                                src={avatar}
                                variant="rounded"
                              />
                            </ListItemAvatar>
                          )}
                          <ListItemText
                            primary={message.message}
                            primaryTypographyProps={{
                              sx: {
                                overflowWrap: "break-word",
                              },
                              border: (theme) =>
                                `1px solid ${
                                  state.selfName === message.senderName
                                    ? theme.palette.blue.main
                                    : theme.palette.corn.main
                                }`,
                              borderRadius: 1,
                              padding: 1,
                              variant: "caption",
                            }}
                          />
                          {state.selfName === message.senderName && (
                            <ListItemAvatar>
                              <Avatar
                                sx={{ marginLeft: 2 }}
                                alt={message.senderName}
                                src={avatar}
                                variant="rounded"
                              />
                            </ListItemAvatar>
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                  <TextField
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 5,
                      right: 5,
                    }}
                    label="Text here.."
                    multiline
                    onChange={handleChangeMessage}
                    onKeyDown={handleKeyDown}
                    placeholder="Press enter to send messages"
                    rows={3}
                    size="small"
                    value={message}
                  />
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default LiveChat;
