import { Theme } from "@mui/material";
import { Chart as ChartType, LegendItem } from "chart.js";

enum lineType {
  Prediction,
  Error,
  Actual,
}

const getOrCreateLegendList = (_chart: ChartType, id: string) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector("ul");
  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "column";
    listContainer.style.flexWrap = "wrap";
    listContainer.style.justifyContent = id.includes("phase-legend-container")
      ? "unset"
      : "center";
    listContainer.style.margin = "0px";
    listContainer.style.padding = "5px";

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

type pluginOptions = {
  containerID: string;
  items: LegendItem[];
  errorMode: boolean;
  phaseLegendContainerID: string;
  phaseLegends: string[];
  theme: Theme;
  layerIds: string[];
  actualContainerId: string;
  errorContainerId: string;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart: ChartType, _args: any, options: pluginOptions): void {
    const model_ul = getOrCreateLegendList(chart, options.containerID);
    const error_ul = getOrCreateLegendList(chart, options.errorContainerId);
    const actual_ul = getOrCreateLegendList(chart, options.actualContainerId);
    options.layerIds.forEach((e: string) => {
      const legendGroup = document.createElement("li");
      legendGroup.id = e;
    });

    // Remove old legend items
    while (model_ul.firstChild) {
      model_ul.firstChild.remove();
    }
    while (error_ul.firstChild) {
      error_ul.firstChild.remove();
    }
    while (actual_ul.firstChild) {
      actual_ul.firstChild.remove();
    }
    // Reuse the built-in legendItems generator
    if (chart?.options?.plugins?.legend?.labels?.generateLabels) {
      // const items = chart.options.plugins.legend.labels.generateLabels(chart);

      options.items.forEach((item: any) => {
        const span = document.createElement("span");
        span.style.alignItems = "center";
        span.style.flex = "1";
        span.style.cursor = "pointer";
        span.style.display = "flex";
        span.style.flexDirection = "row";
        span.style.minHeight = "50px";
        span.onclick = () => {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
          // Code above by itself does not work anymore as hidden is a controlled state now (in LineChart component)
          item.hidden = !item.hidden;
          chart.update();
        };
        const type = item.text.includes("Prediction")
          ? lineType.Prediction
          : item.text.includes("Error")
          ? lineType.Error
          : lineType.Actual;
        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderRadius = "8px";
        boxSpan.style.borderWidth = item.lineWidth + 20 + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "5px";
        boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.fontSize = options.theme.typography.caption
          .fontSize as string;
        textContainer.style.fontWeight = options.theme.typography.caption
          .fontWeight as string;
        textContainer.style.margin = "0px";
        textContainer.style.padding = "0px";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        let baseText = item.text;
        if (type == lineType.Prediction) {
          const baseLabelEndIndex = item.text.indexOf(" Prediction");
          baseText = item.text.substring(0, baseLabelEndIndex);
        }
        if (type == lineType.Actual) {
          const baseLabel = item.text.split(" ")[0];
          baseText = baseLabel + "-Actual Value";
        }
        if (type == lineType.Error) {
          const baseLabelEndIndex = item.text.indexOf(" Error");
          baseText = item.text.substring(0, baseLabelEndIndex);
        }
        const text = document.createTextNode(
          !item.text.includes("Error") ? baseText : item.text
        );
        textContainer.appendChild(text);
        span.appendChild(boxSpan);
        span.appendChild(textContainer);

        const liId = options.layerIds.find((e: string) => baseText.includes(e));
        let legendGroup = document.getElementById(
          (type == lineType.Prediction
            ? options.containerID
            : type == lineType.Actual
            ? options.actualContainerId
            : options.errorContainerId) + liId
        );
        if (legendGroup) legendGroup.appendChild(span);
        else {
          legendGroup = document.createElement("li");
          legendGroup.id =
            (type == lineType.Prediction
              ? options.containerID
              : type == lineType.Actual
              ? options.actualContainerId
              : options.errorContainerId) + liId;
          legendGroup.style.display = "flex";
          legendGroup.style.flexDirection = "row";
          legendGroup.style.borderBottom = "1px solid lightgrey";
          legendGroup.appendChild(span);
        }
        if (item.text.includes("Prediction")) {
          model_ul.appendChild(legendGroup);
        } else if (item.text.includes("Error")) {
          error_ul.appendChild(legendGroup);
        } else {
          actual_ul.appendChild(legendGroup);
        }
      });
    }
    if (options.phaseLegendContainerID) {
      const ul = getOrCreateLegendList(chart, options.phaseLegendContainerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      options.phaseLegends.forEach((item) => {
        const color = item.includes("Train Phase")
          ? options.theme.palette.blue
          : options.theme.palette.bittersweet;

        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "20px";

        // Color box
        const boxSpan = document.createElement("span");
        // 33 suffix for opacity
        boxSpan.style.background = color.light + "33";
        boxSpan.style.borderColor = color.light + "33";
        boxSpan.style.borderWidth = "0px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "5px";
        boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = color.main;
        textContainer.style.fontSize = options.theme.typography.caption
          .fontSize as string;
        textContainer.style.fontWeight = options.theme.typography.caption
          .fontWeight as string;
        textContainer.style.margin = "0px";
        textContainer.style.padding = "0px";

        const text = document.createTextNode(item);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  },
};

export default htmlLegendPlugin;
