import { AxiosResponse } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { axios } from "utils";

export const useDeleteCase = (): UseMutationResult<
  { case_id: string },
  unknown,
  string,
  unknown
> => {
  const deleteCase = useMutation({
    mutationFn: async (case_id: string) => {
      const response: AxiosResponse<{ case_id: string }> = await axios({
        method: "DELETE",
        url: process.env.REACT_APP_API + `/core/case/${case_id.toString()}`,
      });
      return response.data;
    },
  });

  return deleteCase;
};

export default useDeleteCase;
