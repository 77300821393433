import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActualFileDataColumn,
  RunConfig,
  SolutionConfig,
} from 'types/dataImport.type';
import { IActualColumnState } from 'types/dataImport.type';
import { IActualFile } from 'types/dataImport.type';
import { IActualDataMetadata } from 'types/dataImport.type';

export const dataImportSlice = createSlice({
  name: 'dataImportSlice',
  initialState: {
    actualFile: {
      file: undefined,
    } as IActualFile,
    actualDataFileColumns: {
      file_id: undefined,
      column: {
        target_column: null,
        date_column: null,
        hierarchy_column: null,
        feature_columns: [],
      },
    } as IActualColumnState,
    actualFileDataMetadata: {
      date_format: '',
      time_frequency: '',
    } as IActualDataMetadata,
    solutionConfig: {
      hierarchy: null,
      model_dict: null,
    } as SolutionConfig,
    runConfig: {
      start_date: '',
      end_date: '',
      forecast_horizon: 0,
    } as RunConfig,
    training_run_id: undefined as unknown as string | undefined,
    prediction_run_id: undefined as unknown as string | undefined,
  },
  reducers: {
    setActualFile(state, { payload }: PayloadAction<File>) {
      state.actualFile.file = payload;
    },
    removeActualFile(state) {
      state.actualFile.file = undefined;
    },
    setActualColumnsState(
      state,
      { payload }: PayloadAction<ActualFileDataColumn>
    ) {
      state.actualDataFileColumns.column = {
        ...state.actualDataFileColumns.column,
        ...payload,
      };
    },
    setActualColumnsFileId(state, { payload }: PayloadAction<number>) {
      state.actualDataFileColumns.file_id = payload;
    },
    setActualFileDataMetadata(
      state,
      { payload }: PayloadAction<IActualDataMetadata>
    ) {
      state.actualFileDataMetadata = {
        ...state.actualFileDataMetadata,
        ...payload,
      };
    },

    setSolutionConfig(state, { payload }: PayloadAction<SolutionConfig>) {
      state.solutionConfig = {
        ...state.solutionConfig,
        ...payload,
      };
    },

    setForecastHorizon(state, { payload }: PayloadAction<number>) {
      state.runConfig.forecast_horizon = payload;
    },
    setRunStartEndDate(
      state,
      {
        payload,
      }: PayloadAction<{
        start_date: string;
        end_date: string;
      }>
    ) {
      state.runConfig.start_date = payload.start_date;
      state.runConfig.end_date = payload.end_date;
    },
    setTrainingRunId(state, { payload }: PayloadAction<string | undefined>) {
      state.training_run_id = payload;
    },
    setPredictionRunId(state, { payload }: PayloadAction<string>) {
      state.prediction_run_id = payload;
    },
    clearDataImportState(state) {
      state.actualFile = {
        file: undefined,
      };
      state.actualDataFileColumns = {
        file_id: undefined,
        column: {
          target_column: null,
          date_column: null,
          hierarchy_column: null,
          feature_columns: [],
        },
      };
      state.solutionConfig = {
        hierarchy: null,
        model_dict: null,
      };
      state.actualFileDataMetadata = {
        date_format: '',
        time_frequency: '',
      };
    },
    removeTrainingRunId(state) {
      state.training_run_id = undefined;
    },
    removePredictionRunId(state) {
      state.prediction_run_id = undefined;
    },
    removeDataFileStates(state) {
      state.actualDataFileColumns = {
        file_id: undefined,
        column: {
          target_column: null,
          date_column: null,
          hierarchy_column: null,
          feature_columns: [],
        },
      };
      state.actualFileDataMetadata = {
        date_format: '',
        time_frequency: '',
      };
    },
  },
});

export const dataImportActions = dataImportSlice.actions;
export const dataImportReducer = dataImportSlice.reducer;
