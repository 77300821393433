import baseTheme from "./baseTheme";

// track, thumb and active are derieved from macOS 10.15.7
const scrollBar = {
	track: baseTheme.palette.blue.light,
	thumb: baseTheme.palette.secondary.main,
	active: baseTheme.palette.secondary.dark,
};

// Return type might be changed later on
export default function customScrollbar(options = scrollBar): unknown {
	return {
		scrollbarColor: `${options.thumb} ${options.track}`,
		"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
			backgroundColor: options.track,
			width: 10,
		},
		"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
			borderRadius: baseTheme.shape.borderRadius,
			backgroundColor: options.thumb,
			minHeight: 24,
			border: `2px solid ${options.track}`,
		},
		"&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
			backgroundColor: options.active,
		},
		"&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
			backgroundColor: options.active,
		},
		"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
			backgroundColor: options.active,
		},
		"&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
			backgroundColor: options.track,
		},
	};
}
