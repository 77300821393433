import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { KeycloakResponse } from 'types';
import { checkToken } from 'utils';
import { refreshInstance } from 'utils/axios';

const AUTH_URL = (process.env.REACT_APP_AUTH_API + '/refresh') as string;

const useRefreshToken = (): UseQueryResult<any> => {
  const navigate = useNavigate();
  return useQuery(
    ['refresh_token'],
    async () => {
      const { data } = await refreshInstance({
        method: 'post',
        url: AUTH_URL as string,
        data: {
          refresh_token: checkToken()?.refresh_token as string,
        },
      });
      localStorage.setItem('token', JSON.stringify(data as KeycloakResponse));
      return data;
    },
    {
      enabled: true, // turned off by default, manual refetch is needed
      refetchInterval: 295 * 1000,
      refetchOnWindowFocus: true,
      retry: 1,
      onSuccess: (data) => {
        localStorage.setItem('token', JSON.stringify(data as KeycloakResponse));
      },
      onError: (error) => {
        console.log('useRefreshToken : ', error);
        localStorage.removeItem('token');
        navigate('/signin');
      },
    }
  );
};

export default useRefreshToken;
