import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IChartconfigurationState,
  IFilters,
  ILayerState,
  IDataGroup,
  IConfigState,
} from '../../types/main.type';

export const mainSlice = createSlice({
  name: 'mainSlice',
  initialState: {
    configState: {
      runId: undefined,
      trainRunId: undefined,
      runMetadata: {
        description: '',
        train_end_date: null,
        train_start_date: null,
        prediction_end_date: null,
        prediction_start_date: null,
      },
      entryId: undefined,
    } as IConfigState,
    layerState: {
      layerData: undefined,
    } as ILayerState,
    chartConfigurationState: {
      filters: [],
      dataGroups: null,
    } as IChartconfigurationState,
  },
  reducers: {
    setConfigData(state, { payload }: PayloadAction<IConfigState>) {
      state.configState = {
        ...state.configState,
        ...payload,
      };
    },
    setLayerData(state, { payload }: PayloadAction<ILayerState>) {
      state.layerState = {
        ...state.layerState,
        ...payload,
      };
    },
    setChartConfigurationFilter(state, { payload }: PayloadAction<IFilters[]>) {
      state.chartConfigurationState.filters = [...payload];
    },
    setChartConfigurationDataGroup(
      state,
      { payload }: PayloadAction<IDataGroup[] | null>
    ) {
      if (payload) state.chartConfigurationState.dataGroups = [...payload];
      else state.chartConfigurationState.dataGroups = null;
    },
    clearStates(state) {
      state.configState = {
        runId: undefined,
        runMetadata: {
          description: '',
          train_end_date: '',
          train_start_date: '',
          prediction_end_date: '',
          prediction_start_date: '',
        },
      };
      state.layerState = { layerData: undefined };
      state.chartConfigurationState = { filters: [], dataGroups: null };
    },
  },
});
export const mainActions = mainSlice.actions;
export const mainReducer = mainSlice.reducer;
