import { useEffect, useMemo } from 'react'

import { Button, ListItem, Stack } from '@mui/material'
import { cloneDeep, isEmpty, uniq } from 'lodash'
import { MultiCascader } from 'rsuite'
import createLevels from './createLevels'
import { DataHierarchy, Layer } from 'types'
import { RootState } from 'redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { mainActions } from 'redux/slices/mainSlice'
import { useParams } from 'react-router-dom'

export const getSelectedLayerMeta = (
  option: string,
  layers: Layer[]
): { selectedLayerLength: number; label: string } => {
  let selectedLayerLength = 1
  const selectedLayer = layers.find((layer) => layer.value === option)
  while (!selectedLayer) {
    for (const layer of layers) {
      if (layer.children) {
        selectedLayerLength += getSelectedLayerMeta(
          option,
          layer.children
        ).selectedLayerLength
        return {
          selectedLayerLength,
          label: getSelectedLayerMeta(option, layer.children).label,
        }
      }
    }
  }
  return { selectedLayerLength, label: selectedLayer.label }
}

export const getLayerIdAndLabel = (
  hierarchy: DataHierarchy | Record<string, never>,
  option: string,
  layers: Layer[]
): { id: number; label: string } => {
  let level = 0

  let finalHierarchy = hierarchy
  const selectedLayerMeta = getSelectedLayerMeta(option, layers)
  const selectedLayerLength = selectedLayerMeta.selectedLayerLength

  while (level < selectedLayerLength - 1) {
    finalHierarchy = finalHierarchy.children
    level++
  }
  return { id: finalHierarchy.layer_id, label: selectedLayerMeta.label }
}

export const removeEmptyChildren = (layers: Layer[] | undefined): Layer[] => {
  if (layers) {
    const newLayers = cloneDeep(layers)
    for (const layer of newLayers) {
      if (isEmpty(layer.children)) delete layer.children
      else layer.children = removeEmptyChildren(layer.children)
    }
    return newLayers
  }
  return []
}

const Layers = ({ layers }: { layers: Layer[] | undefined }): JSX.Element => {
  const dispatch = useDispatch()

  const { chartConfigurationState: chartConfiguration } = useSelector(
    (state: RootState) => state.main
  )

  const memoizedLayers = useMemo(() => {
    return removeEmptyChildren(layers)
  }, [layers])

  const layersArray = createLevels(memoizedLayers)

  const { case_id, solution_id } = useParams()

  useEffect(() => {
    const elements = []
    for (const level of layersArray) {
      for (const element of level) {
        const { run_id, ...rest } = element.datasource
        const newElement = {
          id: element.id,
          datasource: {
            ...rest,
            case_id: case_id,
            solution_id: solution_id,
          },
        }
        elements.push(newElement)
      }
    }
    if (isEmpty(elements))
      dispatch(mainActions.setChartConfigurationDataGroup(null))
    else dispatch(mainActions.setChartConfigurationDataGroup(elements))
  }, [layers])

  const onChangeLayers = (value: string[]) => {
    // Sync selected elements with cascader's state
    const newSelectedElements = []
    for (const option of value) {
      for (const level of layersArray) {
        for (const element of level) {
          if (element.datasource.value === option) {
            const { run_id: a, ...rest } = element.datasource
            const newElement = {
              id: element.id,
              datasource: {
                ...rest,
                case_id: case_id,
                solution_id: solution_id,
              },
            }
            newSelectedElements.push(newElement)
          }
        }
      }
    }
    dispatch(mainActions.setChartConfigurationDataGroup(newSelectedElements))
  }

  return (
    <ListItem>
      <MultiCascader
        style={{
          width: '100%',
          minHeight: '10px',
        }}
        menuWidth={250}
        cascade={false}
        countable={false}
        data={memoizedLayers}
        placeholder='Select layers'
        renderMenu={(_children, menu: any) => {
          const values = _children.map((child) => child.value)
          return (
            <Stack width={'100%'}>
              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  sx={{ mx: 2 }}
                  size='small'
                  onClick={() => {
                    onChangeLayers(uniq([...values]) as string[])
                  }}
                >
                  Select All
                </Button>
                <Button
                  sx={{ mx: 2 }}
                  size='small'
                  onClick={() => {
                    onChangeLayers([])
                  }}
                >
                  Deselect All
                </Button>
              </Stack>
              {menu}
            </Stack>
          )
        }}
        onChange={onChangeLayers as any}
        value={chartConfiguration.dataGroups?.map((e) => e.datasource.value)}
      />
    </ListItem>
  )
}

export default Layers
