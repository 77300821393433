import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axios, getDecodenToken } from 'utils';

const useGetUserEntries = (): UseQueryResult<any> => {
  const navigate = useNavigate();
  const role = getDecodenToken();

  const entries = useQuery(
    ['user-entries'],
    async () => {
      const { data } = await axios({
        url: process.env.REACT_APP_API_EXTERNAL + `/external_api`,
      }).catch(() => {
        return { data: [] };
      });
      return data;
    },
    {
      enabled: !role['data-import-enabled'],
      refetchOnWindowFocus: true,
      onError: () => {
        navigate('/');
        return [];
      },
      retry: false,
    }
  );
  return entries;
};

export default useGetUserEntries;
