/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IActualDataFileDropdownInfos,
  ISideInfoColumnState,
  ISideInfoDataFileDropdownInfos,
  ISideInfoFiles,
  SideInfoColumn,
  TrainingDates,
} from "types/stepper.type";
export const stepperSlice = createSlice({
  name: "stepperSlice",
  initialState: {
    sideInfoFiles: {
      files: [],
    } as ISideInfoFiles,
    sideInfoFileColumns: {
      column: [],
    } as ISideInfoColumnState,
    hierarchyDropdown: [] as string[],
    actualDataFileDropdownInfos: {
      options: [],
    } as IActualDataFileDropdownInfos,
    sideInfoDataFileDropdownInfos: {
      file_names: [],
      options: [],
    } as ISideInfoDataFileDropdownInfos,
    trainingDates: {
      default_start_date: new Date(),
      default_end_date: new Date(),
    } as TrainingDates,
  },
  reducers: {
    setSideInfoFilesState(state, { payload }: PayloadAction<File[]>) {
      state.sideInfoFiles.files = [...payload];
    },
    removeSideInfoFile(state, { payload }: PayloadAction<File>) {
      state.sideInfoFiles.files = state.sideInfoFiles.files.filter(
        (file) => file.name !== payload.name
      );
      state.sideInfoDataFileDropdownInfos.file_names =
        state.sideInfoDataFileDropdownInfos.file_names.filter(
          (file_name) => file_name !== payload.name
        );
    },

    removeActualColumns(state, { payload }: PayloadAction<string[]>) {
      for (let i = 0; i < payload.length; i++) {
        if (state.actualDataFileDropdownInfos.options.includes(payload[i])) {
          state.actualDataFileDropdownInfos.options.splice(
            state.actualDataFileDropdownInfos.options.indexOf(payload[i]),
            1
          );
        }
      }
    },
    setSideInfoColumnsState(state, { payload }: PayloadAction<SideInfoColumn>) {
      state.sideInfoFileColumns.column = [
        ...state.sideInfoFileColumns.column,
        payload,
      ];
    },
    removeSideInfoColumns(state, { payload }: PayloadAction<string[]>) {
      for (let i = 0; i < payload.length; i++) {
        if (state.sideInfoDataFileDropdownInfos.options.includes(payload[i])) {
          state.sideInfoDataFileDropdownInfos.options.splice(
            state.sideInfoDataFileDropdownInfos.options.indexOf(payload[i]),
            1
          );
        }
      }
    },

    setHierarchyDropdown(state, { payload }: PayloadAction<string[]>) {
      state.hierarchyDropdown = payload;
    },
    setActualDataFileDropdownInfos(
      state,
      { payload }: PayloadAction<string[]>
    ) {
      state.actualDataFileDropdownInfos.options = [...payload];
    },
    setSideInfoDropdownInfoOptions(
      state,
      { payload }: PayloadAction<string[]>
    ) {
      state.sideInfoDataFileDropdownInfos.options = [
        ...state.sideInfoDataFileDropdownInfos.options,
        ...payload,
      ];
    },
    setSideInfoDropdownInfoFileNames(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.sideInfoDataFileDropdownInfos.file_names = [
        ...state.sideInfoDataFileDropdownInfos.file_names,
        payload,
      ];
    },
    setTrainingDates(state, { payload }: PayloadAction<TrainingDates>) {
      state.trainingDates = {
        ...state.trainingDates,
        ...payload,
      };
    },
    clearStepperStates(state) {
      state.sideInfoDataFileDropdownInfos = {
        file_names: [],
        options: [],
      };
      state.actualDataFileDropdownInfos = {
        options: [],
      };

      state.sideInfoFileColumns = {
        column: [],
      };

      state.sideInfoFiles = {
        files: [],
      };
    },
  },
});

export const stepperActions = stepperSlice.actions;
export const stepperReducer = stepperSlice.reducer;
