import MuiButton from "./MuiButton";
import MuiCircularProgress from "./MuiCircularProgress";
import MuiCssBaseline from "./MuiCssBaseline";
import MuiIconButton from "./MuiIconButton";
import MuiInputBase from "./MuiInputBase";
import MuiLink from "./MuiLink";
import MuiListItemButton from "./MuiListItemButton";
import MuiSlider from "./MuiSlider";
import MuiSnackbar from "./MuiSnackbar";
import MuiTextField from "./MuiTextField";
import MuiTooltip from "./MuiTooltip";

const components = {
	MuiButton,
	MuiCircularProgress,
	MuiCssBaseline,
	MuiIconButton,
	MuiInputBase,
	MuiLink,
	MuiListItemButton,
	MuiSlider,
	MuiSnackbar,
	MuiTextField,
	MuiTooltip,
};

export default components;
