import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react'
import { FaMousePointer, FaKeyboard } from 'react-icons/fa'
import { MdInfo } from 'react-icons/md'
import {
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  useTheme,
  Theme,
  Button,
  Tooltip,
  Typography,
} from '@mui/material'
import Chart, {
  ChartConfiguration,
  ChartData,
  LegendItem,
  // TooltipModel,
} from 'chart.js/auto'
import { format } from 'date-fns'
import {
  htmlLegendPlugin,
  customBackgroundPlugin,
  // customTooltip,
} from 'plugins'
import { getAnomalyAnnotations } from 'views/Main/Chart/annotations'
import { isEmpty } from 'lodash'
import { useQueryClient } from 'react-query'

const chartConfig = (
  layerIds: string[],
  data: ChartData,
  annotations: any,
  errorMode: boolean,
  theme: Theme
): Record<string, any> => {
  return {
    type: 'line',
    data,
    options: {
      plugins: {
        // decimation: { enabled: true },
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: `model-legend-container`,
          errorContainerId: 'error-legend-container',
          actualContainerId: 'actual-legend-container',
          items: [],
          errorMode,
          phaseLegendContainerID: `phase-legend-container`,
          phaseLegends: [],
          theme: theme,
          layerIds: layerIds ?? [],
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        annotation: { annotations },
        zoom: {
          zoom: {
            mode: 'x',
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
          },
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
          },
          limits: { x: { min: 'original', max: 'original' } },
        },
      },
      maintainAspectRatio: false,
      // For performance
      animation: false,
      parsing: false,
      // When last datasets have earlier dates, drawed data indices get bugged if normalized set to true for performance
      // normalized: true,
      responsive: true,
      elements: {
        line: { borderWidth: 1 },
        point: {
          radius: 2,
          hoverRadius: 3,
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false,
      },
      scales: {
        x: {
          type: 'time',
          time: {
            stepSize: 1,
            unit: 'day',
            displayFormats: { day: 'yyyy MMM dd' },
            tooltipFormat: 'yyyy MMM dd',
          },
          ticks: {
            // For performance maxRotation can be set to 0
            source: 'auto',
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: (context: any) =>
              context.tick.value === 0
                ? theme.palette.common.black
                : Chart.defaults.borderColor,
          },
        },
      },
    },
    plugins: [htmlLegendPlugin, customBackgroundPlugin],
  } as ChartConfiguration
}

const LineChart = ({
  chartInstance,
  setChartInstance,
  chartData,
  chartjsData,
  setAnnotations,
  annotations,
}: {
  chartInstance: Chart | undefined
  setChartInstance: Dispatch<SetStateAction<Chart | undefined>>
  chartData: {
    id: string
    results: any[]
  }[]
  chartjsData: ChartData
  setAnnotations: Dispatch<SetStateAction<Record<string, unknown>>>
  annotations: any
}): JSX.Element => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const theme = useTheme()
  const queryClient = useQueryClient()

  const [errorMode, setErrorMode] = useState(false)
  const [showActual, setShowActual] = useState(false)

  const [chartLoadingError, setChartLoadingError] = useState(false)

  function Bomb(): JSX.Element {
    setChartLoadingError(false)
    throw new Error('Something went wrong.')
  }
  useEffect(() => {
    if (chartRef.current) {
      try {
        const layerIds = chartData?.map((e) => e.id)
        const newChartInstance = new Chart(
          chartRef.current,
          chartConfig(
            layerIds,
            chartjsData,
            annotations,
            errorMode,
            theme
          ) as ChartConfiguration
        )
        setChartInstance(newChartInstance)
      } catch (e) {
        setChartLoadingError(true)
      }
    }
  }, [chartRef])

  useEffect(() => {
    return () => {
      queryClient.resetQueries('chartData')
    }
  }, [])

  useEffect(() => {
    if (chartInstance && !isEmpty(chartjsData)) {
      //**chartjs data check added */
      try {
        chartInstance.data = chartjsData
        if (annotations) {
          chartInstance.options.plugins!.annotation = { annotations }
          const phaseLegends: string[] = []
          if (annotations.train)
            phaseLegends.push(
              'Train Phase ' +
                `(${format(
                  new Date(annotations['train'].xMin),
                  'yyyy MMM dd'
                )} - ${format(
                  new Date(annotations['train'].xMax),
                  'yyyy MMM dd'
                )})`
            )
          if (annotations.test)
            phaseLegends.push(
              'Test Phase ' +
                `(${format(
                  new Date(annotations['test'].xMin),
                  'yyyy MMM dd'
                )} - ${format(
                  new Date(annotations['test'].xMax),
                  'yyyy MMM dd'
                )})`
            )
            // Plugin registration doesnt cause Typescript to infer the type
          ;(chartInstance.options.plugins as any)!.htmlLegend.phaseLegends =
            phaseLegends
        }

        // Generic legends - Error mode
        ;(chartInstance.options.plugins as any)!.htmlLegend.errorMode =
          errorMode

        const datasets = chartInstance.data.datasets

        if (!errorMode) {
          for (const [datasetIndex, dataset] of datasets.entries()) {
            if (dataset.label?.includes('Error'))
              chartInstance.setDatasetVisibility(datasetIndex, false)
          }
        }
        if (!showActual) {
          for (const [datasetIndex, dataset] of datasets.entries()) {
            if (
              !dataset.label?.includes('Prediction') &&
              !dataset.label?.includes('Error')
            )
              chartInstance.setDatasetVisibility(datasetIndex, false)
          }
        }

        // https://github.com/chartjs/Chart.js/blob/master/src/plugins/plugin.legend.js (custom generateLabels)
        let items = datasets.map((_dataset: any, index: any): LegendItem => {
          const meta = chartInstance.getDatasetMeta(index)
          return {
            text: datasets[meta.index].label as string,
            fillStyle: datasets[meta.index].backgroundColor as string,
            hidden: meta.hidden, //** !meta.visible */
            fontColor: (datasets[meta.index].label as string).includes('Error')
              ? theme.palette.error.dark
              : (datasets[meta.index].label as string).includes('Prediction')
              ? theme.palette.common.black
              : 'rgb(11 114 32)',
            datasetIndex: meta.index,
          }
        })
        // Remove actual data first
        // items = items.filter(
        //   (item: LegendItem) =>
        //     item.text.includes("Error") || item.text.includes("Prediction")
        // );
        if (!showActual) {
          items = items.filter(
            (item: LegendItem) =>
              item.text.includes('Prediction') || item.text.includes('Error')
          )
        }
        if (!errorMode) {
          items = items.filter(
            (item: LegendItem) => !item.text.includes('Error')
          )
        }

        ;(chartInstance.options.plugins as any)!.htmlLegend.items = items
        chartInstance?.update()
      } catch (e) {
        setChartLoadingError(true)
      }
    }
  }, [chartInstance, annotations, errorMode, showActual])

  const onAnomaliesCheckboxClick = () => {
    setAnnotations((prevAnnotations) => {
      if (showAnomalies)
        return Object.keys(prevAnnotations)
          .filter((key) => !key.includes('anomaly'))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: prevAnnotations[key],
            }
          }, {})
      else
        return {
          ...prevAnnotations,
          ...getAnomalyAnnotations(chartData),
        }
    })
    setShowAnomalies(!showAnomalies)
  }

  const onErrorCheckboxClick = () => setErrorMode(!errorMode)

  const onActualCheckboxClick = () => {
    setShowActual(!showActual)
  }

  const [showAnomalies, setShowAnomalies] = useState(false)
  useEffect(() => {
    setShowAnomalies(false)
  }, [chartData])

  const handleResetZoom = () => {
    chartInstance?.resetZoom()
  }
  return (
    <>
      <Stack direction='row' alignItems='center' spacing={1}>
        {chartLoadingError && <Bomb />}
        <Stack direction='row'>
          <Button onClick={handleResetZoom} size='small'>
            Reset Zoom
          </Button>
          <Tooltip
            title={
              <Stack spacing={1}>
                <Typography variant='caption'>
                  <span
                    style={{
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    Hold CTRL (
                    <FaKeyboard size={8} />) then click and drag pointer
                  </span>{' '}
                  (<FaMousePointer size={8} />) for panning
                </Typography>

                <Typography variant='caption'>
                  <span
                    style={{
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    Drag pointer (<FaMousePointer size={8} />)
                  </span>{' '}
                  for zooming
                </Typography>
              </Stack>
            }
          >
            <Box
              sx={{
                color: theme.palette.blue.main,
              }}
            >
              <MdInfo />
            </Box>
          </Tooltip>
        </Stack>

        <div
          id={`phase-legend-container`}
          style={{ marginRight: 'auto' }}
        ></div>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAnomalies}
                onClick={onAnomaliesCheckboxClick}
              />
            }
            label='Anomalies'
          />
          {/* {(annotations?.train || annotations?.test) && chartInstance &&  ( */}
          <>
            <FormControlLabel
              control={<Checkbox onClick={onErrorCheckboxClick} />}
              label='Errors'
            />
            <FormControlLabel
              control={<Checkbox onClick={onActualCheckboxClick} />}
              label='Actual Data'
            />
          </>
          {/* )} */}
        </FormGroup>
      </Stack>

      <Box width='100%' height={400} position='relative'>
        <canvas id={'2'} ref={chartRef} />
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{ flex: showActual ? 1 : 0 }}
          id={`actual-legend-container`}
        ></div>
        <div style={{ flex: 4 }} id={`model-legend-container`}></div>
        <div
          style={{ flex: errorMode ? 4 : 0 }}
          id={`error-legend-container`}
        ></div>
      </Box>
    </>
  )
}
export default LineChart
