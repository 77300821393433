import { useState, useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import {
  AddCase,
  UploadCaseData,
  AddSolution,
  UploadSolutionConfig,
  StartPrediction,
  StartTraining,
} from './UploadDataComponents';
import {
  useGetAllCases,
  useGetRunInfoOfPipeline,
  useGetStartedRunsByType,
  useGetStartedPrediction,
  useGetAllSolutions,
  useGetSuccessRunsByType,
} from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions, stepperActions } from 'redux/slices';
import { Case, Solution } from 'types';
import { Grid } from '@mui/material';
import { dataImportActions } from 'redux/slices/dataImportSlice';
import { RootState } from 'redux/store';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import {
  CaseDescription,
  SolutionDescription,
  TrainingDescription,
} from './UploadDataComponents/Descriptions';

const initialCase = {
  case_id: '',
  case_name: '',
  case_description: '',
};

const initialSolution = {
  solution_id: '',
  solution_name: '',
  solution_description: '',
};

export default function Home() {
  const dispatch = useDispatch();

  const [selectedCase, setSelectedCase] = useState<Case>(initialCase);
  const [selectedSolution, setSelectedSolution] =
    useState<Solution>(initialSolution);

  const [activeStep, setActiveStep] = useState(0);

  const [loadingTraining, setLoadingTraining] = useState(false);
  const [loadingPrediction, setLoadingPrediction] = useState(false);
  const [useMostRecentTraining, setUseMostRecentTraining] = useState(false);
  const [useMostRecentPrediction, setUseMostRecentPrediction] = useState(false);

  //** Eger hic training baslatılmamıssa değer null olacaktır. bir traınıng başlatılmışsa false , bitmişse true olacaktır .  */
  const [newTrainingFinished, setNewTrainingFinished] = useState<
    null | boolean
  >(null);

  const [newPredictionFinished, setNewPredictionFinished] = useState<
    null | boolean
  >(null);

  const [isSolutionExist, setIsSolutionExist] = useState(false);

  const { data: cases, refetch: refetchCase } = useGetAllCases();

  const { data: solutions, refetch: refetchSolution } = useGetAllSolutions(
    selectedCase?.case_id
  );

  const { training_run_id, prediction_run_id } = useSelector(
    (state: RootState) => state.dataImport
  );

  const {
    data: trainingRunsWithStatus,
    refetch: refetchSuccessFinetuning,
    isFetching: isFetchingTrainingRunsWithStatus,
  } = useGetSuccessRunsByType({
    case_id: selectedCase.case_id,
    solution_id: selectedSolution.solution_id,
    run_type: 'finetuning',
  });

  const {
    data: predictionRunsWithStatus,
    refetch: refetchSuccessPrediction,
    isFetching: isFetchingPredictionRunsWithStatus,
  } = useGetSuccessRunsByType({
    case_id: selectedCase.case_id,
    solution_id: selectedSolution.solution_id,
    run_type: 'prediction',
  });

  const {
    refetch: refetchGetStartedPrediction,
    isFetching: isFetchingStartedPrediction,
  } = useGetStartedPrediction({
    case_id: selectedCase.case_id,
    solution_id: selectedSolution.solution_id,
    run_type: 'prediction',
    training_run_id: training_run_id,
  });

  const { data: trainingInfo } = useGetRunInfoOfPipeline({
    case_id: selectedCase.case_id,
    solution_id: selectedSolution.solution_id,
    run_type: 'finetuning',
    run_id: training_run_id,
  });

  const { data: predictionInfo } = useGetRunInfoOfPipeline({
    case_id: selectedCase.case_id,
    solution_id: selectedSolution.solution_id,
    run_type: 'prediction',
    run_id: prediction_run_id,
  });

  useEffect(() => {
    refetchGetStartedPrediction();
  }, [training_run_id]);

  const {
    isFetching: isFetchingStartedFinetunings,
    data: startedFinetuningRuns,
    refetch: refetchStartedFinetunings,
  } = useGetStartedRunsByType(
    selectedCase?.case_id,
    selectedSolution?.solution_id,
    'finetuning'
  );

  // Solution Update
  useEffect(() => {
    setSelectedSolution(initialSolution);
    dispatch(dataImportActions.clearDataImportState());
    dispatch(stepperActions.clearStepperStates());
    refetchSolution();
  }, [selectedCase]);

  useEffect(() => {
    if (startedFinetuningRuns && !isEmpty(startedFinetuningRuns)) {
      setUseMostRecentPrediction(false);
      dispatch(
        dataImportActions.setTrainingRunId(startedFinetuningRuns[0].run_id)
      );
    } else if (
      trainingInfo &&
      trainingInfo.run_status === 'SUCCESS' &&
      newTrainingFinished === false
    ) {
      toast.success('Training finished successfully', {
        icon: '🚀',
      });
      refetchSuccessFinetuning();
      refetchSuccessPrediction();
      setNewTrainingFinished(true);
      setLoadingTraining(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (
      trainingInfo &&
      (trainingInfo.run_status === 'FAILURE' ||
        trainingInfo.run_status === 'CANCELED' ||
        trainingInfo.run_status === 'CANCELING') &&
      newTrainingFinished === false
    ) {
      dispatch(commonActions.setShowPubNub(true));
      toast.error('Training failure ', {
        icon: '🤯',
      });

      setNewTrainingFinished(true);
      setLoadingTraining(false);
    }
  }, [trainingInfo, startedFinetuningRuns]);

  useEffect(() => {
    if (
      predictionInfo &&
      predictionInfo.run_status === 'SUCCESS' &&
      newPredictionFinished === false
    ) {
      refetchSuccessPrediction();
      toast.success('Prediction finished successfully', {
        icon: '🚀',
      });
      setNewPredictionFinished(true);
      setLoadingPrediction(false);
    } else if (
      predictionInfo &&
      (predictionInfo.run_status === 'FAILURE' ||
        predictionInfo.run_status === 'CANCELED' ||
        predictionInfo.run_status === 'CANCELING') &&
      newPredictionFinished === false
    ) {
      dispatch(commonActions.setShowPubNub(true));
      toast.error('Prediction failure ', {
        icon: '🤯',
      });
      setNewPredictionFinished(true);
      setLoadingPrediction(false);
    } else if (
      predictionInfo &&
      (predictionInfo.run_status === 'STARTED' ||
        predictionInfo.run_status === 'STARTING')
    ) {
      setLoadingPrediction(true);
    }
  }, [predictionInfo]);

  useEffect(() => {
    refetchSuccessFinetuning();
    refetchSuccessPrediction();
    refetchStartedFinetunings();
    dispatch(dataImportActions.removeTrainingRunId());
    dispatch(dataImportActions.removePredictionRunId());
    setNewTrainingFinished(null);
    setLoadingTraining(false);
    setLoadingPrediction(false);
    if (selectedSolution.solution_id === '') {
      setIsSolutionExist(false);
    } else {
      setIsSolutionExist(true);
    }
  }, [selectedSolution]);

  useEffect(() => {
    return () => {
      dispatch(dataImportActions.removeTrainingRunId());
      dispatch(dataImportActions.removePredictionRunId());
      setNewPredictionFinished(null);
      setNewTrainingFinished(null);
    };
  }, []);

  const steps = [
    {
      label: ' Define Case',
      description: CaseDescription(selectedCase, activeStep),
      render: (
        <AddCase
          setActiveStep={setActiveStep}
          refetchCase={refetchCase}
          refetchSolution={refetchSolution}
          caseOptions={cases ?? []}
          caseInfo={selectedCase}
          setCaseInfo={setSelectedCase}
        />
      ),
    },
    {
      label: 'Upload Actual Data',
      description: '',
      render: (
        <UploadCaseData
          setActiveStep={setActiveStep}
          caseInfo={selectedCase}
          isSideInfo={false}
        />
      ),
    },
    {
      label: 'Define Solution',
      description: SolutionDescription(selectedSolution, activeStep),
      render: (
        <AddSolution
          isSolutionExist={isSolutionExist}
          setActiveStep={setActiveStep}
          isFetchingStartedFinetunings={isFetchingStartedFinetunings}
          isFetchingTrainingRunsWithStatus={isFetchingTrainingRunsWithStatus}
          solutions={solutions ?? []}
          refetchSolution={refetchSolution}
          solutionInfo={selectedSolution}
          setSolutionInfo={setSelectedSolution}
          caseInfo={selectedCase}
        />
      ),
    },
    {
      label: 'Solution Details',
      description: ``,
      render: (
        <UploadSolutionConfig
          selectedCase={selectedCase}
          selectedSolution={selectedSolution}
          setSelectedSolution={setSelectedSolution}
          isSolutionExist={isSolutionExist}
          setActiveStep={setActiveStep}
          refetchSolution={refetchSolution}
        />
      ),
    },
    {
      label: 'Start Training ',
      description: TrainingDescription(
        trainingRunsWithStatus,
        startedFinetuningRuns,
        activeStep
      ),
      render: (
        <StartTraining
          setActiveStep={setActiveStep}
          trainingInfo={trainingInfo}
          loadingContinue={
            isFetchingPredictionRunsWithStatus &&
            isFetchingTrainingRunsWithStatus &&
            isFetchingStartedPrediction
          }
          trainingRunsWithStatus={trainingRunsWithStatus}
          finetuningRunning={
            trainingInfo?.run_status === 'STARTED' ||
            trainingInfo?.run_status === 'STARTING'
          }
          newTrainingFinished={newTrainingFinished}
          setNewTrainingFinished={setNewTrainingFinished}
          useMostRecentTraining={useMostRecentTraining}
          setUseMostRecentTraining={setUseMostRecentTraining}
          caseInfo={selectedCase}
          solutionInfo={selectedSolution}
          loading={loadingTraining}
          setLoading={setLoadingTraining}
        />
      ),
    },
    {
      label: 'Start Prediction',
      description: '',
      render: (
        <StartPrediction
          setActiveStep={setActiveStep}
          newPredictionFinished={newPredictionFinished}
          predictionRunsWithStatus={predictionRunsWithStatus}
          trainingInfo={trainingInfo}
          setNewPredictionFinished={setNewPredictionFinished}
          useMostRecentPrediction={useMostRecentPrediction}
          setUseMostRecentPrediction={setUseMostRecentPrediction}
          caseInfo={selectedCase}
          loading={loadingPrediction}
          solutionInfo={selectedSolution}
          setLoading={setLoadingPrediction}
        />
      ),
    },
  ];

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Grid
        item
        sx={{
          mt: 15,
        }}
        xs={1}
        md={3}
        lg={3}
      >
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  <>
                    {index === 5 && (
                      <Typography variant='caption'>Last step</Typography>
                    )}

                    <Typography variant='caption'>
                      {step.description}
                    </Typography>
                  </>
                }
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid
        item
        sx={{
          mt: 20,
          pl: 15,
        }}
        xs={10}
        md={8}
        lg={8}
      >
        <Grid item xs={12} md={8} lg={8}>
          {steps[activeStep].render}
        </Grid>
      </Grid>
    </Grid>
  );
}
