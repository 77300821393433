import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReactComponent as RunLoading } from 'assets/Loading/RunLoading.svg';
import {
  Box,
  TextField,
  Grid,
  Tooltip,
  Autocomplete,
  Grow,
} from '@mui/material';
import { addCaseSolutionStyle } from './Style';
import { Case } from 'types';
import { MdInfo } from 'react-icons/md';
import DeleteCase from '../DeleteDataComponents/DeleteCase';
import SwitchSelector from 'react-switch-selector';
import { LoadingButton } from '@mui/lab';
import { useGetDataImports, useSendNewCase } from 'hooks';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { stepperActions } from 'redux/slices';
import { dataImportActions } from 'redux/slices/dataImportSlice';
const caseSwipeOptions = [
  {
    label: 'Select Existing',
    value: false,
    selectedBackgroundColor: '#3475da',
    innerHeight: 50,
  },
  {
    label: 'Add New',
    value: true,
    selectedBackgroundColor: '#63a771',
  },
];

const initialCase = {
  case_id: '',
  case_name: '',
  case_description: '',
  case_column_types: [],
  solutions: [],
};

const AddCase = ({
  setActiveStep,
  refetchCase,
  refetchSolution,
  caseOptions,
  caseInfo,
  setCaseInfo,
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  refetchCase: any;
  refetchSolution: any;
  caseOptions: Case[];
  caseInfo: Case;
  setCaseInfo: Dispatch<SetStateAction<Case>>;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { mutateAsync: sendNewCase } = useSendNewCase(setCaseInfo);

  const {
    data: dataImports,
    refetch: refetchDataImport,
    isFetching: isFetchingDataImport,
  } = useGetDataImports({
    case_id: caseInfo?.case_id,
  });

  const [addNewCase, setAddNewCase] = useState<boolean>(false);
  const [isCaseExist, setIsCaseExist] = useState(false);

  const onChangeSelectedCase = async (value: Case) => {
    if (value) setCaseInfo(value);
    else {
      setCaseInfo(initialCase);
    }
  };

  const onClick = async () => {
    if (isCaseExist) {
      if (dataImports && !isEmpty(dataImports)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      await sendNewCase(caseInfo);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    refetchDataImport();
    const caseIds = caseOptions?.map((e) => e.case_id);
    if (caseIds.includes(caseInfo.case_id)) setIsCaseExist(true);
    else setIsCaseExist(false);
  }, [caseInfo, caseOptions]);

  useEffect(() => {
    if (dataImports && !isEmpty(dataImports)) {
      dispatch(
        dataImportActions.setRunStartEndDate({
          start_date: dataImports.data_metadata.data_start_date,
          end_date: dataImports.data_metadata.data_end_date,
        })
      );
      dispatch(
        stepperActions.setHierarchyDropdown(
          dataImports.data_metadata.hierarchy_ids
        )
      );
      dispatch(
        stepperActions.setTrainingDates({
          default_start_date: new Date(
            dataImports.data_metadata.data_start_date as unknown as Date
          ),
          default_end_date: new Date(
            dataImports.data_metadata.data_end_date as unknown as Date
          ),
        })
      );
    }
  }, [dataImports]);
  return (
    <Grow in={true} timeout={800}>
      <Box sx={addCaseSolutionStyle}>
        <Grid item xs={9} lg={8} xl={6}>
          <SwitchSelector
            onChange={(e) => {
              setCaseInfo(initialCase);
              setAddNewCase(e as boolean);
            }}
            options={caseSwipeOptions}
            initialSelectedIndex={0}
            backgroundColor={'#353b48'}
            fontColor={'#f5f6fa'}
          />
        </Grid>
        <Grid item xs={9} lg={8} xl={6} mt={4}>
          {!addNewCase ? (
            <Autocomplete
              fullWidth
              options={caseOptions ?? []}
              onChange={(_event, value) => onChangeSelectedCase(value as Case)}
              getOptionLabel={(option) => option.case_name}
              isOptionEqualToValue={() => true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  helperText={'Select from existing cases'}
                  label='Case'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.case_id}>
                  {option.case_name}
                  <Box ml='auto' color='primary.main'>
                    <DeleteCase
                      refetchSolution={refetchSolution}
                      refetch={refetchCase}
                      case_id={option.case_id}
                    />
                    <Tooltip title={option.case_description}>
                      <span>
                        <MdInfo />
                      </span>
                    </Tooltip>
                  </Box>
                </li>
              )}
              size='medium'
              value={isCaseExist ? caseInfo : null}
            />
          ) : (
            <Grid>
              <TextField
                fullWidth
                variant='standard'
                label='Case Name'
                helperText={
                  caseInfo.case_name?.trimEnd() === ''
                    ? 'Case Name should not be empty'
                    : 'Case name helps you distinguish the case from others while using the dashboard.'
                }
                type='text'
                margin='normal'
                error={caseInfo.case_name?.trimEnd().trimStart() === ''}
                onChange={(e) => {
                  setCaseInfo({ ...caseInfo, case_name: e.target.value });
                }}
                value={caseInfo.case_name}
              />
              <TextField
                fullWidth
                variant='standard'
                label='Case Description'
                helperText={'Additional information about the case '}
                type='text'
                margin='normal'
                onChange={(e) =>
                  setCaseInfo({ ...caseInfo, case_description: e.target.value })
                }
                value={caseInfo.case_description}
              />
            </Grid>
          )}
        </Grid>
        <Grid>
          <LoadingButton
            loading={isFetchingDataImport}
            loadingIndicator={<RunLoading />}
            sx={{ mb: 2, mt: 7, minWidth: '100px' }}
            variant={'contained'}
            color={'secondary'}
            disabled={
              (caseInfo.case_name?.trimEnd() === '' &&
                caseInfo.case_name.trimEnd() === '') ||
              isFetchingDataImport
            }
            onClick={onClick}
          >
            Continue
          </LoadingButton>
        </Grid>
      </Box>
    </Grow>
  );
};
export default AddCase;
