import { format } from "date-fns";
import { isEmpty } from "lodash";
import { Case, DataImportSteps, RunInfo, Solution } from "types";

export const CaseDescription = (selectedCase: Case, activeStep: number) => {
  if (activeStep !== DataImportSteps.AddCase)
    return selectedCase.case_name !== "" ? (
      <div>
        Case Name :
        <span style={{ color: "red" }}> {selectedCase.case_name}</span>
        {selectedCase.case_description !== "" ? (
          <div>
            Case Description :
            <span style={{ color: "red" }}>
              {selectedCase.case_description}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  else return "";
};

export const SolutionDescription = (
  selectedSolution: Solution,
  activeStep: number
) => {
  if (activeStep !== DataImportSteps.AddSolution)
    return selectedSolution.solution_name !== "" ? (
      <div>
        Solution Name :
        <span style={{ color: "red" }}> {selectedSolution.solution_name}</span>
        {selectedSolution.solution_description !== "" ? (
          <div>
            Solution Description :
            <span style={{ color: "red" }}>
              {selectedSolution.solution_description}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  else return "";
};
export const SolutionDetailsDescription = (
  selectedSolution: Solution,
  activeStep: number
) => {
  if (activeStep !== DataImportSteps.AddSolution)
    return selectedSolution.solution_name !== "" ? (
      <div>
        Solution Name :
        <span style={{ color: "red" }}> {selectedSolution.solution_name}</span>
        {selectedSolution.solution_description !== "" ? (
          <div>
            Solution Description :
            <span style={{ color: "red" }}>
              {selectedSolution.solution_description}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  else return "";
};

export const TrainingDescription = (
  trainingRunsWithStatus: RunInfo[] | undefined,
  startedFinetuningRuns: RunInfo[] | undefined,
  activeStep: number
) => {
  if (activeStep !== DataImportSteps.StartTraining)
    if (startedFinetuningRuns && !isEmpty(startedFinetuningRuns)) {
      return (
        <div>
          Training Date :
          <span style={{ color: "red" }}>
            {" "}
            {format(
              new Date(startedFinetuningRuns[0]?.created_at),
              "dd-MM-yyyy HH:mm:ss"
            )}
          </span>
          <div>
            Status :
            <span style={{ color: "red" }}>
              {startedFinetuningRuns[0].run_status}
            </span>
          </div>
        </div>
      );
    } else if (!isEmpty(trainingRunsWithStatus) && trainingRunsWithStatus) {
      return (
        <div>
          Training Date :
          <span style={{ color: "red" }}>
            {" "}
            {format(
              new Date(trainingRunsWithStatus[0]?.created_at),
              "dd-MM-yyyy HH:mm:ss"
            )}
          </span>
          <div>
            Status :
            <span style={{ color: "red" }}>
              {trainingRunsWithStatus[0].run_status}
            </span>
          </div>
        </div>
      );
    } else return "";
};

export const PredictionDescription = (
  predictionRunsWithStatus: RunInfo[] | undefined,
  activeStep: number,
  trainingRunId: string | null
) => {
  if (activeStep !== DataImportSteps.StartPrediction)
    return (
      !isEmpty(predictionRunsWithStatus) &&
      predictionRunsWithStatus &&
      predictionRunsWithStatus[0].train_run_id === trainingRunId && (
        <div>
          Prediction Date :
          <span style={{ color: "red" }}>
            {format(
              new Date(predictionRunsWithStatus[0]?.created_at),
              "dd-MM-yyyy HH:mm:ss"
            )}
          </span>
          <div>
            Status :
            <span style={{ color: "red" }}>
              {predictionRunsWithStatus[0].run_status}
            </span>
          </div>
        </div>
      )
    );
  else return "";
};
