import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import { IRunMetadata } from 'types/main.type';

const DatePickerRange = ({
  runMetadata,
  setStartDate,
  setEndDate,
}: {
  runMetadata?: IRunMetadata;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
}): JSX.Element => {
  const onChange = (dates: [start: Date, end: Date] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const onClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const [defaultCalenderValue, setDefaultCalendarValue] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (runMetadata) setDefaultCalendarValue(runMetadata.train_start_date);
  }, [runMetadata]);

  return (
    <DateRangePicker
      defaultCalendarValue={[
        new Date(defaultCalenderValue ?? '2021-01-01'),
        new Date(defaultCalenderValue ?? '2021-01-01'),
      ]}
      style={{ width: '100%', minHeight: '10px' }}
      format='yyyy-MM-dd'
      placement='bottom'
      onClean={onClean}
      onOk={onChange}
      appearance='default'
      placeholder={'Default'}
      disabledDate={(date) => {
        return !moment(date).isBetween(
          moment(runMetadata?.train_start_date).subtract(1, 'days'),
          moment(runMetadata?.prediction_end_date).add(1, 'days')
        );
      }}
    />
  );
};

export default DatePickerRange;
