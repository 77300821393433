import { CssBaseline, ThemeProvider } from '@mui/material';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { IconContext } from 'react-icons';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { RequireAuth } from 'components';
import LiveChat from 'components/LiveChat';
import { LiveChatProvider } from 'contexts/LiveChatContext';
import { Main } from 'layouts';
import theme from 'theme';
import { checkToken, getDecodenToken } from 'utils';
import Help from 'views/Help';
import Home from 'views/Home';
import MainPage from 'views/Main';
import Signin from 'views/Signin';
import { store } from './redux/store';
import 'styles/StepperIcons.css';
import 'styles/toastNotification.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rsuite/dist/rsuite.min.css';
import 'assets/Cascader.css';
import 'assets/DatePicker.css';
import 'chartjs-adapter-date-fns';
import 'styles/index.css';
import 'antd/dist/antd.css';

import { ToastContainer } from 'react-toastify';
import { useState } from 'react';

const NotFoundRouteRedirect = (): JSX.Element => <Navigate to='/signin' />;

const RoleRoute = (): JSX.Element => <Navigate to='/signin' />;

const SigninWithRedirect = ({
  setDataImportEnabled,
}: {
  setDataImportEnabled: any;
}): JSX.Element => {
  const role = getDecodenToken();
  setDataImportEnabled(
    role?.hasOwnProperty('data-import-enabled')
      ? role['data-import-enabled']
      : false
  );

  return checkToken()?.access_token ? (
    <>
      {role && !role['data-import-enabled'] ? (
        <Navigate to={`/external`} />
      ) : (
        <Navigate to='/' />
      )}
    </>
  ) : (
    <Signin />
  );
};

const ExternalWithRedirect = (): JSX.Element => {
  const role = getDecodenToken();

  return checkToken()?.access_token ? (
    <>
      {role && role['data-import-enabled'] ? (
        <Navigate to={`/`} />
      ) : (
        <MainPage />
      )}
    </>
  ) : (
    <Signin />
  );
};

Chart.register(annotationPlugin, zoomPlugin);

const queryClient = new QueryClient();
const App = (): JSX.Element => {
  const [dataImportEnabled, setDataImportEnabled] = useState(false);
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IconContext.Provider
            value={{ size: '24px', style: { verticalAlign: 'middle' } }}
          >
            <BrowserRouter>
              <Routes>
                <Route
                  element={
                    <SigninWithRedirect
                      setDataImportEnabled={setDataImportEnabled}
                    />
                  }
                  path='/signin'
                />
                <Route
                  path='/'
                  element={
                    <RequireAuth>
                      <>
                        <ToastContainer />
                        <Main />
                        {process.env.REACT_APP_PUBNUB_CHAT === 'true' && (
                          <LiveChatProvider>
                            <LiveChat />
                          </LiveChatProvider>
                        )}
                      </>
                    </RequireAuth>
                  }
                >
                  <Route
                    path='/'
                    element={dataImportEnabled ? <Home /> : <RoleRoute />}
                  />

                  <Route
                    path='/case/:case_id/solution/:solution_id'
                    element={<MainPage />}
                  />
                  <Route path='/external' element={<ExternalWithRedirect />} />
                  <Route element={<Help />} path='/help' />
                </Route>
                <Route element={<NotFoundRouteRedirect />} path='*' />
              </Routes>
            </BrowserRouter>
          </IconContext.Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
