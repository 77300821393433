import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  SimplePaletteColorOptions,
} from "@mui/material";

import components from "./components";
import palette from "./palette";

declare module "@mui/material/styles" {
  interface Palette {
    bittersweet: PaletteColor;
    blue: PaletteColor;
    corn: PaletteColor;
    white: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    bittersweet: PaletteColorOptions;
    blue: PaletteColorOptions;
    corn: PaletteColorOptions;
    white: SimplePaletteColorOptions;
  }
}

const baseThemeOptions = {
  typography: {
    fontFamily: "Source Sans Pro",
  },
  palette,
};

const overridesTheme = createTheme({ ...baseThemeOptions, components });

export default overridesTheme;
