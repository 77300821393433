import { PaletteOptions } from "@mui/material";

const palette: PaletteOptions = {
  primary: {
    main: "#63a771",
  },
  secondary: {
    main: "#4a469d",
  },
  bittersweet: {
    light: "#eb6d6c",
    main: "#3b3890",
    dark: "#a13332",
  },
  blue: { light: "#5c90e1", main: "#3475da", dark: "#245198" },
  corn: { light: "#f4d040", main: "#f2c511", dark: "#a9890b" },
  white: { main: "#F1F1F1" },
};

export default palette;
