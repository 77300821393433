import useSendNewSolution from "hooks/endpoints/useSendNewSolution";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Case, Solution } from "types";

export const useHandleSendSolution = (
  caseInfo: Case,
  setSelectedSolution: Dispatch<SetStateAction<Solution>>,
  solutionInfo: Solution,
  isSolutionExists: boolean
) => {
  const { mutateAsync: sendNewSolution } =
    useSendNewSolution(setSelectedSolution);
  const { solutionConfig } = useSelector(
    (state: RootState) => state.dataImport
  );
  const sendSolution = async () => {
    if (!isSolutionExists)
      await sendNewSolution({
        ...solutionInfo,
        case_id: caseInfo.case_id.toString(),
        solutionConfig,
      });
  };
  return {
    sendSolution,
  };
};
