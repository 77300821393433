import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonState } from '../../types/main.type';

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: {
    commonState: {
      showPubNub: false,
    } as ICommonState,
  },
  reducers: {
    setShowPubNub(state, { payload }: PayloadAction<boolean>) {
      state.commonState.showPubNub = payload;
    },
  },
});
export const commonActions = commonSlice.actions;

export const commonReducer = commonSlice.reducer;

export default commonReducer;
