import {
  Box,
  Link,
  ListItemIcon,
  MenuItem,
  Toolbar as MUIToolbar,
  Typography,
} from "@mui/material";
import { MdLogout, MdOutlineHome } from "react-icons/md";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "assets/Logo.svg";
import { getDecodenToken } from "utils";

const Toolbar = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleExit = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const onClickBackArrow = () => {
    navigate("/");
  };

  return (
    <>
      <MUIToolbar>
        <Link
          component={RouterLink}
          to={
            getDecodenToken()["data-import-enabled"]
              ? "/"
              : window.location.pathname
          }
        >
          {process.env.REACT_APP_SHOW_LOGO === "true" && <Logo />}
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        {location.pathname !== "/" && getDecodenToken()["data-import-enabled"] && (
          <MenuItem
            sx={{ fontWeight: "bold", pl: 4 }}
            onClick={onClickBackArrow}
          >
            <ListItemIcon sx={{ color: "bittersweet.main" }}>
              <MdOutlineHome size={27} />
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem sx={{ fontWeight: "bold" }} onClick={handleExit}>
          <ListItemIcon sx={{ color: "bittersweet.main" }}>
            <MdLogout size={27} />
          </ListItemIcon>
          Logout
        </MenuItem>
        {/* <IconButton
          sx={{
            color: "secondary.main",
            mr: 1,
          }}
          component={RouterLink}
          to="/help"
        >
          <MdHelp />
        </IconButton> */}
        <Typography
          color="corn.main"
          variant="caption"
          fontWeight="bold"
        ></Typography>
      </MUIToolbar>
    </>
  );
};

export default Toolbar;
