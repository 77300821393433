import { createTheme } from "@mui/material";

import palette from "./palette";

const baseThemeOptions = {
	typography: {
		fontFamily: "Source Sans Pro",
	},
	palette,
};

export default createTheme(baseThemeOptions);
