import { useQuery, UseQueryResult } from "react-query";

import { RunInfo, RunType } from "types";
import { axios } from "utils";
import { sorter } from "utils/runSorter";

const useGetStartedRunsByType = (
  case_id?: string,
  solution_id?: string,
  run_type?: RunType
): UseQueryResult<RunInfo[]> => {
  const runDetails = useQuery(
    ["runsByType", case_id],
    async () => {
      if (case_id !== "" && solution_id !== "") {
        const { data } = await axios({
          url:
            process.env.REACT_APP_API +
            `/core/case/${case_id?.toString()}/solution/${solution_id?.toString()}/run/${run_type}`,
        });
        return data
          .filter(
            (e: RunInfo) =>
              (e.run_status === "STARTING" || e.run_status === "STARTED") &&
              e.run_type === run_type
          )
          .sort(sorter);
      }
      return [];
    },
    { enabled: !!run_type }
  );
  return runDetails;
};

export default useGetStartedRunsByType;
