import { mainActions } from "redux/slices/mainSlice";
import { axios } from "utils";
import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { sorter } from "utils/runSorter";
import { RunInfo } from "types";
// TODO: WILL BE CHANGED
const setRunId = async (
  inspectedCase: string | undefined,
  inspectedSolution: string | undefined,
  dispatch: any,
  setIsRunProcessWaiting?: Dispatch<SetStateAction<boolean>>
) => {
  if (inspectedCase && inspectedSolution) {
    const { data }: AxiosResponse<RunInfo[]> = await axios({
      url:
        process.env.REACT_APP_API +
        `/core/case/${inspectedCase.toString()}/solution/${inspectedSolution.toString()}/run`,
    });
    if (data.length > 0) {
      const predictionRuns = data.filter(
        (run: any) =>
          run.run_type === "prediction" && run.run_status === "SUCCESS"
      );
      const finetuningRuns = data.filter(
        (run: any) =>
          run.run_type === "finetuning" && run.run_status === "SUCCESS"
      );

      if (predictionRuns.length > 0) {
        predictionRuns.sort(sorter);
        dispatch(
          mainActions.setConfigData({
            runId: predictionRuns[0].run_id,
            trainRunId: predictionRuns[0].train_run_id,
          })
        );
        if (setIsRunProcessWaiting) setIsRunProcessWaiting(false);
      } else if (finetuningRuns.length > 0) {
        finetuningRuns.sort(sorter);
        dispatch(
          mainActions.setConfigData({
            runId: finetuningRuns[0].run_id,
          })
        );
        if (setIsRunProcessWaiting) setIsRunProcessWaiting(false);
      } else {
        if (setIsRunProcessWaiting) setIsRunProcessWaiting(true);
      }
    }
  }
  return null;
};

export default setRunId;
