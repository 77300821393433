import { Components } from "@mui/material";

const MuiInputBase: Components["MuiInputBase"] = {
	styleOverrides: {
		input: {
			"&:-webkit-autofill": {
				transitionDelay: "9999s",
				transitionProperty: "background-color, color",
			},
		},
	},
};

export default MuiInputBase;
