import { ReactComponent as RunLoading } from "assets/Loading/RunLoading.svg";
import { LoadingButton } from "@mui/lab";
import {
  TextField,
  FormHelperText,
  Chip,
  Autocomplete,
  Grid,
  Button,
  Grow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dataImportActions } from "redux/slices/dataImportSlice";
import { RootState } from "redux/store";
import { useHandleSendSolution } from "../UploadHooks/useSendSolution";
import { Dispatch, SetStateAction } from "react";
import { Case, Solution } from "types";

const UploadSolutionConfig = ({
  selectedCase,
  setSelectedSolution,
  selectedSolution,
  isSolutionExist,

  refetchSolution,
  setActiveStep,
}: {
  selectedCase: Case;
  setSelectedSolution: Dispatch<SetStateAction<Solution>>;
  selectedSolution: Solution;
  isSolutionExist: boolean;
  refetchSolution: any;
  setActiveStep: Dispatch<SetStateAction<number>>;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { solutionConfig } = useSelector(
    (state: RootState) => state.dataImport
  );

  const hierarchyColumns =
    useSelector((state: RootState) => state.stepper.hierarchyDropdown) || [];

  const selectedHierarchy =
    useSelector(
      (state: RootState) => state.dataImport.solutionConfig.hierarchy
    ) || [];

  const { sendSolution } = useHandleSendSolution(
    selectedCase,
    setSelectedSolution,
    selectedSolution,
    isSolutionExist
  );
  const onChange = (values: any) => {
    dispatch(dataImportActions.setSolutionConfig(values));
  };

  const optionRenderer = (option: any, selected: string) => {
    if (selected === "SELECT ALL") {
      return (
        <Button
          key={option.key}
          sx={{ mx: 2, mb: 1 }}
          size="small"
          onClick={() => {
            onChange({
              hierarchy: [...hierarchyColumns],
            });
          }}
        >
          <li>Select All</li>
        </Button>
      );
    }
    return (
      <li {...option} key={option.key}>
        {selected}
      </li>
    );
  };
  const handleContinue = async () => {
    if (!isSolutionExist) {
      await sendSolution();
    }
    refetchSolution();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grow in={true} timeout={800}>
      <Grid item xs={9} lg={8} xl={6}>
        <Autocomplete
          multiple
          options={["SELECT ALL", ...hierarchyColumns]}
          getOptionLabel={(option) => option}
          onChange={(_e, value) => {
            onChange({
              hierarchy: value,
            });
          }}
          value={selectedHierarchy}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={optionRenderer}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Hierarchy" />
          )}
        />
        <FormHelperText>
          Select Hierarcies to include in forecast model training. Multiple
          selection possible.
        </FormHelperText>
        <Autocomplete
          fullWidth
          options={["SHORT", "MEDIUM", "LONG"]}
          getOptionLabel={(option) => option}
          onChange={(_e, value) =>
            onChange({
              model_dict: value,
            })
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Training Duration"
            />
          )}
        />
        <FormHelperText>Select Training Duration</FormHelperText>
        <LoadingButton
          loadingIndicator={<RunLoading />}
          sx={{ mb: 2, mt: 7, minWidth: "100px" }}
          variant={"contained"}
          color={"secondary"}
          disabled={!(solutionConfig.hierarchy && solutionConfig.model_dict)}
          onClick={handleContinue}
        >
          Continue
        </LoadingButton>
        <Button
          color="inherit"
          onClick={handleBack}
          sx={{ ml: 5, mb: 2, mt: 7 }}
        >
          Back
        </Button>
      </Grid>
    </Grow>
  );
};
export default UploadSolutionConfig;
