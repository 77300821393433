import { KeycloakResponse } from "types";
const checkToken = (): KeycloakResponse | null => {
	try {
		const token = localStorage.getItem("token");
		if (token) {
			return JSON.parse(token) as KeycloakResponse;
		}
	} catch (error) {
		console.log("checkToken : ", error);
	}
	localStorage.removeItem("token");
	return null;
};

export default checkToken;
