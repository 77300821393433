export enum FETCH_STATUS {
  "UNKNOWN",
  "INFO",
  "SUCCESS",
  "ERROR",
}
export interface IProcess {
  processName?: string;
  error?: string;
  message?: string;
  status?: FETCH_STATUS;
}
export const CASE_SOLUTION_UPLOAD_ERROR = "CASE_SOLUTION_UPLOAD";
export const SUCCESSFULL_IMPORT = "SUCCESSFULL_IMPORT";
