export type TextLists = {
	header?: string;
	text?: string;
	children: TextLists;
}[];

// If children is empty, header doesnt do anything, else choose text or header

const lists: TextLists = [
	{
		header: "User Actions",
		children: [
			{
				text: "System works on commonly used browsers, Chrome is recommended.",
				children: [],
			},
			{
				text: "System is composed of two main components. The first component is the left panel in which one can define data sources, hierarchy levels and filters. Filters include fields such as date interval, numeric interval, etc. These fields are the defining properties of chart visualization.",
				children: [],
			},
			{
				text: "The second component is on the right panel in which one can observe the created charts.",
				children: [],
			},
			{
				text: "When you sign in to the system, you can use the 'Add Chart' button to create a chart. You will have a selected 'Chart 1' instance by default each time you sign in to the system. You can also see the list of charts on a list and the selected one with a yellow background color.",
				children: [],
			},
			{
				text: "All the fields you are editing/observing will always affect the selected chart. You can change the name of the chart and configure all the fields mentioned before.",
				children: [],
			},
			{
				text: "All the numeric filters will be calculated based on selected hierarchy levels collectively and will be applied while creating the chart.",
				children: [],
			},
			{
				text: "You have to select at least one data source and a hierarchy level to be able to 'Apply' and create the chart. As you can also observe, after selecting the data source, the first hierarchy level will come in as selected by default.",
				children: [],
			},
			{
				text: "You can left-click and hold it to create a rectangle to zoom into that part on the chart. You can also hold CTRL and drag the pointer on chart for panning.",
				children: [],
			},
			{
				text: "You can use the colorful chart legends to show/hide the related data lines on the chart. The best prediction result is selected by default with the corresponding legend on the chart.",
				children: [],
			},
			{
				text: "You can also use the checkboxes on the top right of each chart zone to show/hide the related data:",
				children: [
					{
						text: "You can use Actual Data checkbox to show/hide all of the actual data you've selected and Anomalies checkbox for the anomalies of them.",
						children: [],
					},
					{
						text: "  You can also use Errors checkbox to show chart legends related to prediction errors. They are configured to be closed by default but you can show/hide them one by one by clicking on the related legends.",
						children: [],
					},
				],
			},
			{
				text: "You can also observe the Train Phase and Test Phase legends with the corresponding background color on the chart to indicate the time intervals of the phases.",
				children: [],
			},
			{
				text: "You can also export the data that is used in a given chart. All of the fields used while creating the chart are considered in the exported data.",
				children: [],
			},
			{
				text: "You can also click on the down arrow by the side of the name of the chart to read the related data explanation used in that specific chart.",
				children: [],
			},
		],
	},
	{
		header: "Data Upload",
		children: [
			{
				text: "When you click on the vertical menu button on the top bar, you'll see 'Upload Data' button to upload data.",
				children: [],
			},
			{
				text: "Your data must have the same format as the data you gave for the first model trials.",
				children: [],
			},
			{
				text: "You can always use this feature to keep the selected data source up to date.",
				children: [],
			},
		],
	},
];

export default lists;
