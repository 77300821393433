import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProcess } from "../../types/notification.type";

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    processInfo: {} as IProcess,
  },
  reducers: {
    setProcessInfo(state, { payload }: PayloadAction<IProcess>) {
      state.processInfo = payload;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
