import { useEffect, useState } from 'react';

import { ListItem } from '@mui/material';

import { format } from 'date-fns';

import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DateRangePicker } from 'components';
import { mainActions } from 'redux/slices/mainSlice';
import { RootState } from 'redux/store';

const Filters = (): JSX.Element => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const filterState = useSelector(
    (state: RootState) => state.main.chartConfigurationState.filters
  );
  const { runMetadata } = useSelector(
    (state: RootState) => state.main.configState
  );

  useEffect(() => {
    const filters = cloneDeep(filterState).filter(
      (filter) => filter.filter_type !== 'date'
    );
    if (startDate) {
      const startDateFilter = filters.find(
        (filter: any) =>
          filter.operator === '>=' && filter.filter_type === 'date'
      );
      if (startDateFilter)
        startDateFilter.filter_value = format(startDate, 'yyyy-MM-dd');
      else
        filters.push({
          filter_type: 'date',
          operator: '>=',
          filter_value: format(startDate, 'yyyy-MM-dd'),
        });
    }
    if (endDate) {
      const endDateFilter = filters.find(
        (filter) => filter.operator === '<=' && filter.filter_type === 'date'
      );
      if (endDateFilter)
        endDateFilter.filter_value = format(endDate, 'yyyy-MM-dd');
      else
        filters.push({
          filter_type: 'date',
          operator: '<=',
          filter_value: format(endDate, 'yyyy-MM-dd'),
        });
    }
    dispatch(mainActions.setChartConfigurationFilter(filters));
  }, [startDate, endDate]);

  // useEffect(() => {
  // 	if (!isEmpty(values)) {
  // 		setChartConfigurations((prevChartConfigurations) => {
  // 			const newChartConfigurations = cloneDeep(prevChartConfigurations);
  // 			const currentChartConfiguration = newChartConfigurations.find(
  // 				(option) => option.id === selectedConfiguration.id
  // 			) as ChartConfiguration;

  // 			for (const dataGroup of currentChartConfiguration.dataGroups) {
  // 				for (const key in values) {
  // 					if (Object.prototype.hasOwnProperty.call(values, key)) {
  // 						// Check if filter already exits, if not add a new one
  // 						const filter_type = key.includes("_lower")
  // 							? key.split("_lower")[0]
  // 							: key.split("_upper")[0];
  // 						const operator = key.includes("_lower") ? ">=" : "<=";

  // 						const filter = dataGroup!.filters.find(
  // 							(option) =>
  // 								option.filter_type === filter_type &&
  // 								option.operator === operator
  // 						);

  // 						if (filter) {
  // 							if (values[key]) filter.filter_value = values[key];
  // 							else {
  // 								dataGroup!.filters = dataGroup!.filters.filter(
  // 									(option) => option !== filter
  // 								);
  // 							}
  // 						} else {
  // 							if (values[key]) {
  // 								const newFilter: {
  // 									filter_type: string;
  // 									filter_value: unknown;
  // 									operator: string;
  // 								} = { filter_type: "", filter_value: "", operator: "" };
  // 								newFilter.filter_type = key;
  // 								newFilter.filter_value = values[key];
  // 								if (key.includes("lower")) {
  // 									newFilter.filter_type = filter_type;
  // 									newFilter.operator = ">=";
  // 								} else {
  // 									newFilter.filter_type = key.split("_upper")[0];
  // 									newFilter.operator = "<=";
  // 								}
  // 								dataGroup!.filters.push(newFilter);
  // 							}
  // 						}
  // 					}
  // 				}
  // 			}

  // 			return newChartConfigurations;
  // 		});
  // 	}
  // }, [values]);

  return (
    <>
      <ListItem>
        <DateRangePicker
          runMetadata={runMetadata}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </ListItem>
    </>
  );
};

export default Filters;
