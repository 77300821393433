export const style = {
  top: "70%",
  left: "50%",
  width: 400,
  border: "2px solid #000",
  borderRadius: 10,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  marginTop: 3,
  marginLeft: 10,
  marginBottom: 3,
};
export const addCaseSolutionStyle: any = {
  display: "flex",
  flexDirection: "column",
};

export const dialogStyle = {
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
};
