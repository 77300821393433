import { Button, Typography } from "@mui/material";
import { TbFaceIdError } from "react-icons/tb";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) {
  console.log(error);
  return (
    <div
      id="wrapper"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        id="info"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TbFaceIdError size={500}></TbFaceIdError>
        <Typography style={{ fontSize: "24px", fontFamily: "cursive" }}>
          Something went wrong...
        </Typography>
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 6, mb: 6 }}
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </div>
    </div>
  );
}
