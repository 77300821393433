import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useSendChunkCompleted, useSendFiles } from "hooks";
import { Case } from "types";
import { SetStateAction, Dispatch, useEffect, useState } from "react";
import useSendChunk from "hooks/endpoints/useSendChunk";

const chunkSize = 5 * 1024 * 1024; // 5 mb
const maxFileSize = 5 * 1024 * 1024; // 5 mb
//** data geldikten sonra dataimport id ile upload chunk yapıp . Upload chunk process bitince queryi resetlemek gerekiyor */
export const useHandleSendFiles = (
  caseInfo: Case,
  setFileUploadError: Dispatch<SetStateAction<boolean>>,
  setFileUploadLoading: Dispatch<SetStateAction<boolean>>,
  setProgress: Dispatch<SetStateAction<number>>,
  progress: number
) => {
  const [beginningOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [counter, setCounter] = useState(1);
  const [chunkCount, setChunkCount] = useState(0);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  const { sideInfoFiles } = useSelector((state: RootState) => state.stepper);

  const { actualFileDataMetadata } = useSelector(
    (state: RootState) => state.dataImport
  );
  const { actualDataFileColumns: actualDataColumns } = useSelector(
    (state: RootState) => state.dataImport
  );

  const { actualFile } = useSelector((state: RootState) => state.dataImport);

  useEffect(() => {
    setProgress(0);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setCounter(1);
    setSessionId(undefined);
    if (actualFile?.file)
      setChunkCount(Math.ceil(actualFile.file.size / chunkSize));
  }, [actualFile]);

  const { mutateAsync: sendFile } = useSendFiles(
    actualFileDataMetadata,
    setProgress,
    actualDataColumns.column,
    setFileUploadLoading
  );

  const { mutateAsync: sendChunk } = useSendChunk(
    setSessionId,
    setProgress,
    counter,
    setCounter,
    chunkCount,
    setFileUploadLoading,
    setFileUploadError
  );
  const { mutateAsync: sendChunkCompleted } = useSendChunkCompleted(
    setProgress,
    setCounter,
    actualFileDataMetadata,
    actualDataColumns.column,
    setFileUploadLoading,
    setFileUploadError
  );

  useEffect(() => {
    const sendChunkData = async () => {
      if (progress > 0 && progress < 100 && actualFile.file) {
        const chunk = actualFile.file.slice(beginningOfTheChunk, endOfTheChunk);
        await sendChunk({
          beginningOfTheChunk,
          endOfTheChunk,
          setBeginingOfTheChunk,
          setEndOfTheChunk,
          chunkSize,
          is_side_info: false,
          session_id: sessionId,
          chunk,
          case_id: caseInfo.case_id,
          file: actualFile.file,
        });
      } else if (
        actualFile.file &&
        actualFile?.file?.size > maxFileSize &&
        progress === 100
      ) {
        await sendChunkCompleted({
          session_id: sessionId,
          case_id: caseInfo.case_id,
        });
      }
    };
    sendChunkData().catch((e) => console.log(e));
  }, [progress]);

  const sendFiles = async () => {
    if (caseInfo.case_id !== "" && actualFile.file) {
      if (actualFile.file.size < chunkSize) {
        setProgress(100);
        await sendFile({
          is_side_info: false,
          case_id: caseInfo.case_id,
          file: actualFile.file,
        });
      } else {
        const chunk = actualFile.file.slice(beginningOfTheChunk, endOfTheChunk);
        await sendChunk({
          beginningOfTheChunk,
          endOfTheChunk,
          setBeginingOfTheChunk,
          setEndOfTheChunk,
          chunkSize,
          is_side_info: false,
          session_id: sessionId,
          chunk,
          case_id: caseInfo.case_id,
          file: actualFile.file,
        });
      }
    }

    if (caseInfo.case_id !== "" && sideInfoFiles.files.length > 0) {
      for (const file of sideInfoFiles.files)
        await sendFile({
          is_side_info: true,
          case_id: caseInfo.case_id,
          file: file,
        });
    }
  };

  return {
    sendFiles,
  };
};
