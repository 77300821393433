import { ListItem } from '@mui/material';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from 'redux/slices';
import { RootState } from 'redux/store';
import { DateRangePicker } from 'rsuite';
import { IEnrtyDetails } from 'types/main.type';

export const EntryDatePicker = ({
  entryDetails,
}: {
  entryDetails: IEnrtyDetails;
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const filterState = useSelector(
    (state: RootState) => state.main.chartConfigurationState.filters
  );

  const onChange = (dates: [start: Date, end: Date] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const onClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    const filters = cloneDeep(filterState).filter(
      (filter) => filter.filter_type !== 'date'
    );
    if (startDate) {
      const startDateFilter = filters.find(
        (filter: any) =>
          filter.operator === '>=' && filter.filter_type === 'date'
      );
      if (startDateFilter)
        startDateFilter.filter_value = format(startDate, 'yyyy-MM-dd');
      else
        filters.push({
          filter_type: 'date',
          operator: '>=',
          filter_value: format(startDate, 'yyyy-MM-dd'),
        });
    }
    if (endDate) {
      const endDateFilter = filters.find(
        (filter) => filter.operator === '<=' && filter.filter_type === 'date'
      );
      if (endDateFilter)
        endDateFilter.filter_value = format(endDate, 'yyyy-MM-dd');
      else
        filters.push({
          filter_type: 'date',
          operator: '<=',
          filter_value: format(endDate, 'yyyy-MM-dd'),
        });
    }
    dispatch(mainActions.setChartConfigurationFilter(filters));
  }, [startDate, endDate]);

  return (
    <ListItem>
      <DateRangePicker
        onChange={onChange}
        onClean={onClean}
        defaultCalendarValue={[
          new Date(entryDetails?.start_date ?? '2021-01-01'),
          new Date(entryDetails?.start_date ?? '2021-01-01'),
        ]}
        style={{ width: '100%', minHeight: '10px' }}
        format='yyyy-MM-dd'
        placement='bottom'
        placeholder={'Select Date Interval'}
        disabledDate={(date) => {
          return !moment(date).isBetween(
            moment(entryDetails?.start_date).subtract(1, 'days'),
            moment(entryDetails?.end_date).add(1, 'days')
          );
        }}
      />
    </ListItem>
  );
};
