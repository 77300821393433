import { Components } from "@mui/material";

const MuiButton: Components["MuiButton"] = {
	defaultProps: {
		color: "secondary",
		variant: "outlined",
	},
};

export default MuiButton;
