import { cloneDeep, isEmpty } from "lodash";
import { Layer, RunExplanations } from "types";

type LayerWithParentId = { parentId?: string } & Layer;

export type ElementType = {
  label: string;
  parentId?: string;
  childrenIds?: string[];
  explanations?: RunExplanations;
};

const formatLayer = (layer: LayerWithParentId): any => {
  return {
    id: layer.label,
    datasource: {
      layer_id: layer.layer_id,
      value: layer.value,
    },
  };
};

const createLevels = (layers: LayerWithParentId[]): any[] => {
  const levels: any[][] = [];
  const queue = cloneDeep(layers);
  let currentLayer;
  while (!isEmpty(queue)) {
    const level = [];
    let levelSize = queue.length;
    while (levelSize--) {
      currentLayer = queue.shift();
      level.push(formatLayer(currentLayer as LayerWithParentId));
      if (isEmpty(currentLayer?.children)) continue;
      else {
        for (const option of currentLayer?.children as LayerWithParentId[]) {
          queue.push({ ...option, parentId: currentLayer?.value });
        }
      }
    }
    levels.push(level);
  }
  return levels;
};

export default createLevels;
