import { useQuery, UseQueryResult } from "react-query";

import { axios } from "utils";

const useGetFinetuningMetadata = ({
  case_id,
  solution_id,
}: {
  case_id: string | undefined;
  solution_id: string | undefined;
}): UseQueryResult<any> => {
  const finetuningMetadata = useQuery("finetuningMetadata", async () => {
    const { data } = await axios({
      url:
        process.env.REACT_APP_API +
        `/core/case/${case_id?.toString()}/solution/${solution_id?.toString()}`,
    });
    return data.metadata.finetuning;
  });
  return finetuningMetadata;
};

export default useGetFinetuningMetadata;
